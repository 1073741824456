import {
  hideElementsWithCssClassName,
  showElementsWithCssClassName,
} from '../../common/styles';

export const getParentClassnameForEvent = (
  eventName,
  categoryName,
  mfrType,
) => {
  return (
    `bb-${removeSpaces(categoryName)}-${removeSpaces(eventName)} ` +
    `event-${mfrType}`
  ); //
};

export const removeSpaces = (specificString) => {
  return specificString.split(' ').join('');
};

let lastLineChartMouseX = 0;
export const setLastLineChartMouseX = (newLastLineChartMouseX) => {
  lastLineChartMouseX = newLastLineChartMouseX;
};

export const getLastLineChartMouseX = () => {
  return lastLineChartMouseX;
};

let lastLineChartMouseY = {};
export const setLastLineChartMouseY = (newLastLineChartMouseY) => {
  lastLineChartMouseY = newLastLineChartMouseY;
};

export const getLastLineChartMouseY = () => {
  return lastLineChartMouseY;
};

let lastZoomStartDate = {};
export const setLastZoomStartDate = (newLastZoomStartDate) => {
  lastZoomStartDate = newLastZoomStartDate;
};

export const getLastZoomStartDate = () => {
  return lastZoomStartDate;
};

let lastZoomEndDate = {};
export const setLastZoomEndDate = (newLastZoomEndDate) => {
  lastZoomEndDate = newLastZoomEndDate;
};

export const getLastZoomEndDate = () => {
  return lastZoomEndDate;
};

let lastEventStripRangeStartDate = {};
export const setLastEventStripRangeStartDate = (
  newLastEventStripRangeStartDate,
) => {
  lastEventStripRangeStartDate = newLastEventStripRangeStartDate;
};

export const getLastEventStripRangeStartDate = () => {
  return lastEventStripRangeStartDate;
};

let lastEventStripRangeEndDate = {};
export const setLastEventStripRangeEndDate = (
  newLastEventStripRangeEndDate,
) => {
  lastEventStripRangeEndDate = newLastEventStripRangeEndDate;
};

export const getLastEventStripRangeEndDate = () => {
  return lastEventStripRangeEndDate;
};

let latestVectorChartPan = 0;
export const setLatestVectorChartPan = (newVectorChartPan) => {
  latestVectorChartPan = newVectorChartPan;
};

export const getLatestVectorChartPan = () => {
  return latestVectorChartPan;
};

let latestVectorChartScaleX = 1;
export const setLatestVectorChartScaleX = (newVectorChartScaleX) => {
  latestVectorChartScaleX = newVectorChartScaleX;
};

export const getLatestVectorChartScaleX = () => {
  return latestVectorChartScaleX;
};

let latestDragEventDate = new Date();
export const setLatestDragEventDate = (newDragEventDate) => {
  latestDragEventDate = newDragEventDate;
};

export const getLatestDragEventDate = () => {
  return latestDragEventDate;
};

let initialPanValue = new Date();
export const setInitialPanValue = (newInitialPanValue) => {
  initialPanValue = newInitialPanValue;
};

export const getInitialPanValue = () => {
  return initialPanValue;
};

let latestDragXPosition = 0;
export const setLatestDragXPosition = (newDragXPosition) => {
  latestDragXPosition = newDragXPosition;
};

export const getLatestDragXPosition = () => {
  return latestDragXPosition;
};

let latestMouseXPosition = 0;
export const setLatestMouseXPosition = (newMouseXPosition) => {
  latestMouseXPosition = newMouseXPosition;
};

export const getLatestMouseXPosition = () => {
  return latestMouseXPosition;
};

let dragStartXPosition = 0;
export const setDragStartXPosition = (newDragStartXPosition) => {
  dragStartXPosition = newDragStartXPosition;
};

export const getDragStartXPosition = () => {
  return dragStartXPosition;
};

export const decorateEachMemberWithValue = (
  configuration,
  propName,
  propValue,
) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      configuration[categoryKey].members[memberKey][propName] = propValue;
    }
  }
  return configuration;
};

export const hideAndShowEventsBasedOnCurrentConfiguration = (configuration) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      const cssClassNameOfSuchEvents = getCSSClassnameForEvent(
        categoryKey,
        memberKey,
      );
      if (configuration[categoryKey].members[memberKey].selected) {
        showElementsWithCssClassName(cssClassNameOfSuchEvents);
      } else {
        hideElementsWithCssClassName(cssClassNameOfSuchEvents);
      }
    }
  }
};

export const setProperCSSClassNameToEachEventFromConfiguration = (
  configuration,
) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      configuration[categoryKey].members[memberKey].itemClassName =
        getCSSClassnameForEvent(categoryKey, memberKey);
    }
  }
  return configuration;
};

export const getCSSClassnameForEvent = (eventCategory, eventName) => {
  if (eventCategory && eventName) {
    return `bb-${eventCategory.split(' ').join('')}-${eventName
      .split(' ')
      .join('')}`;
  }
};

export const clearPropertyFromObject = (prop, object) => {
  object[prop] = null;
  delete object.prop;
};
