import { put, take, call } from 'redux-saga/effects';
import {
  getDetailsForSelectedEventSuccess,
  getSelectedEventDetails,
  getDetailsForSelectedEventError,
  setLoadingEventDetails,
} from '../../../reducers/eventDetailsSlice';

/* Used in order to acquire detailed event information
  for a specific event from the list of events in the Events page */
export default function* selectedEventPageDetailsSaga(api) {
  while (true) {
    const selectedEventDetailsAction = yield take(getSelectedEventDetails.type);
    yield put(setLoadingEventDetails(true));
    yield put(getDetailsForSelectedEventSuccess(null));
    const { entityId, eventData } = selectedEventDetailsAction.payload;
    yield call(getDetailsForSelectedEvent, api, entityId, eventData);
  }
}
/** Create request to get details for selected event
 * @param {any} entityId - Entity ID
 * @param {object} eventData - Data for the event (data,type,id)
 */
export function* getDetailsForSelectedEvent(api, entityId, eventData) {
  try {
    const detailsForSelectedEventData = yield call(
      api.getDetailsForSelectedEvent,
      entityId,
      eventData?.id,
    );

    const detailsForSelectedEvent = detailsForSelectedEventData.data;
    detailsForSelectedEvent.level = eventData?.level;
    yield put(getDetailsForSelectedEventSuccess(detailsForSelectedEvent));
    yield put(setLoadingEventDetails(false));
  } catch (error) {
    yield put(setLoadingEventDetails(false));
    yield put(getDetailsForSelectedEventError(error));
  }
}
