import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  VectorClickedElementType,
  VectorConfigurationInitialState,
} from '../components/Vector/types/vectorDataChartTypes';
import { RootState } from './rootReducer';

export const vectorConfigurationInitialState: VectorConfigurationInitialState =
  {
    vectorConfigurationData: {},
    clickedElementType: null,
    vectorChartLoaded: false,
  };

export const vectorConfiguration = createSlice({
  name: 'vectorConfiguration',
  initialState: vectorConfigurationInitialState,
  reducers: {
    setVectorConfigurationSuccess: (state, action: PayloadAction<any>) => {
      state.vectorConfigurationData = action.payload;
    },
    setVectorClickedItemType: (
      state,
      action: PayloadAction<VectorClickedElementType | null>,
    ) => {
      state.clickedElementType = action.payload;
    },

    setVectorChartLoaded: (state, action: PayloadAction<boolean>) => {
      state.vectorChartLoaded = action.payload;
    },
    resetVectorConfiguration: (state) => {
      state.clickedElementType = null;
      state.vectorChartLoaded = false;
    },
  },
});

export const {
  setVectorClickedItemType,
  setVectorConfigurationSuccess,
  setVectorChartLoaded,
  resetVectorConfiguration,
} = vectorConfiguration.actions;

export const getVectorConfiguration = createAction(
  'GET_VECTOR_CHARTS_CONFIGURATION_REQUEST',
);

// selectors
export const vectorConfigurationSelectors = {
  selectClickedElementType: (state: RootState) =>
    state.vectorConfiguration.clickedElementType,
  isClickedElement: (
    state: RootState,
    element: VectorClickedElementType | undefined,
  ) => {
    return element
      ? state.vectorConfiguration.clickedElementType === element
      : true;
  },
  selectIsVectorChartLoaded: (state: RootState) =>
    state.vectorConfiguration.vectorChartLoaded,
};

export default vectorConfiguration.reducer;
