import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { addSeconds } from 'date-fns';
import {
  CRITICAL_STATE,
  CRITICAL_ALARM_STATE,
  NON_CRITICAL_STATE,
  OUT_OF_OPERATION_STATE,
  IN_OPERATION_STATE,
  FEED_GRABBER_DUMPING_COLOR,
  FEED_GRABBER_GRABBING_COLOR,
  FEED_GRABBER_SCANNING_COLOR,
  FEED_GRABBER_CALIBRATION_COLOR,
} from '../constants';
import {
  calculateAccuracy,
  getRandomHEXColor,
  isActivityCriticalAlarm,
} from '../../components/Vector/util';
import {
  GrabTaskDetails,
  GrabSubactivtyDetails,
  GrabSubactivtyTypes,
  ExtendedActivityObject,
} from '../../reducers/types/vectorDetailsTypes';
import {
  Activity,
  ActivitySpan,
  ActivityType,
  BaseActivitySpan,
  CriticalAlarmActivitySpan,
  MFRActivities,
  MFRLoadingActivitySpan,
  PdbActivities,
  VectorChartData,
  VectorDataState,
  VectorSettings,
} from '../../reducers/types/vectorDataTypes';

export const getAlarmDefinitionByAlarmCode = (
  alarmCode,
  alarmTextsData,
): { [key: string]: any } | null => {
  let alarmDetails = {};
  const decimalAlarmCode = alarmCode.toString().includes('0x')
    ? parseInt(alarmCode, 16)
    : alarmCode;
  const hexAlarmCode = alarmCode.toString().includes('0x')
    ? alarmCode
    : `0x${alarmCode.toString(16).toLowerCase()}`;

  alarmTextsData?.forEach((currentAlarmDefinition) => {
    if (
      currentAlarmDefinition.id === decimalAlarmCode ||
      currentAlarmDefinition.idHex === hexAlarmCode
    ) {
      alarmDetails = { ...currentAlarmDefinition, idHex: hexAlarmCode };
    }
  });
  return !isEmpty(alarmDetails) ? alarmDetails : null;
};

export const getAlarmDescriptionWithParametersFilledIn = (
  alarmDetails,
  alarmDefinition,
  vectorSettings: VectorSettings,
) => {
  let alarmDescription = getAlarmTranslation(alarmDefinition)?.description;

  // Alarm description on Vector Events page is already created and assigned to translatedName.
  if (alarmDetails.translatedName) {
    return alarmDetails.translatedName;
  }

  alarmDefinition?.parameters?.forEach((parameterValue, parameterIndex) => {
    const paramRegex = new RegExp(`(<P${parameterIndex + 1}>)`);
    const paramValueToBePut =
      alarmDetails?.parameters?.[parameterIndex] !== undefined
        ? alarmDetails?.parameters?.[parameterIndex]
        : alarmDetails?.activity?.alarmParameters?.[parameterIndex] !==
          undefined
        ? alarmDetails?.activity?.alarmParameters?.[parameterIndex]
        : ' ';
    let valueToDisplay;
    if (
      alarmDetails.activity.alarmCode === 5640 &&
      vectorSettings?.feedNames?.[paramValueToBePut]
    ) {
      valueToDisplay = vectorSettings?.feedNames?.[paramValueToBePut];
    } else {
      valueToDisplay = paramValueToBePut;
    }
    alarmDescription = alarmDescription.replace(paramRegex, valueToDisplay);
  });
  return alarmDescription;
};

export const getAlarmDescriptionWithParameterNameFilledInForAlarms = (
  alarmDetails,
  alarmDefinition,
  vectorSettings,
) => {
  let alarmDescription = getAlarmTranslation(alarmDefinition)?.description;
  alarmDefinition?.parameters.forEach((parameterValue, parameterIndex) => {
    const paramRegex = new RegExp(`(<P${parameterIndex + 1}>)`);
    const paramValueToBePut =
      alarmDetails.activity.alarmParameters[parameterIndex]?.toString() || ' ';
    let valueToDisplay;
    if (
      alarmDetails.activity.alarmCode === 5640 &&
      vectorSettings?.feedNames?.[paramValueToBePut]
    ) {
      valueToDisplay = vectorSettings.feedNames[paramValueToBePut];
    } else {
      valueToDisplay = paramValueToBePut;
    }
    alarmDescription = alarmDescription.replace(paramRegex, valueToDisplay);
  });
  return alarmDescription;
};

export const getFenceNameColorByColors = (
  fenceName,
  fenceNamesObject,
  colorsArray,
) => {
  let fenceNameColor;
  Object.keys(fenceNamesObject).forEach(
    (currentFenceId, currentFenceIdIndex) => {
      if (fenceNamesObject[currentFenceId] === fenceName) {
        fenceNameColor = colorsArray[currentFenceIdIndex];
      }
    },
  );
  return fenceNameColor;
};

export const getEventColors = (
  eventData,
  alarmDescription,
  operationMode,
  state,
) => {
  let eventColor;
  if (
    alarmDescription === CRITICAL_STATE ||
    (operationMode || state) === CRITICAL_ALARM_STATE
  ) {
    eventColor = eventData.colorError;
  } else if (
    (alarmDescription || state) === NON_CRITICAL_STATE ||
    (operationMode || state) === OUT_OF_OPERATION_STATE
  ) {
    eventColor = eventData.colorWarning;
  } else if ((operationMode || state) === IN_OPERATION_STATE) {
    eventColor = eventData.colorSuccess;
  } else {
    eventColor = eventData.color;
  }
  return eventColor;
};

export const getFeedIdToColorDictionary = (feedNames, arrayOfColors) => {
  const feedIdToColorDictionary = {};
  Object.keys(feedNames).forEach((currentFeedId, currentFeedNameIndex) => {
    feedIdToColorDictionary[currentFeedId] =
      arrayOfColors[currentFeedNameIndex % arrayOfColors.length];
    if (feedIdToColorDictionary && feedNames && feedNames[currentFeedId]) {
      feedIdToColorDictionary[feedNames[currentFeedId]] =
        arrayOfColors[currentFeedNameIndex % arrayOfColors.length];
    }
  });
  return feedIdToColorDictionary;
};

export const getAlarmTranslation = (alarmDefinition) => {
  return alarmDefinition?.translations[0];
};

export const getEventsFromMfrChartObject = (
  latestAppliedProperties,
  eventTypeToCategoryDictionary,
  backendData,
  mfrId,
) => {
  const eventsObject = {};
  for (const category in latestAppliedProperties) {
    eventsObject[category] = {};
    for (const eventType in latestAppliedProperties[category].members) {
      eventsObject[category][eventType] = [];
    }
  }
  backendData.chartData[`${mfrId}Events`].forEach((currentEvent) => {
    const currentNumberOfRetries = currentEvent.numberOfRetries;
    const currentEventType = currentEvent.type;
    const currentEventCategory =
      eventTypeToCategoryDictionary[currentEventType] ||
      eventTypeToCategoryDictionary[currentEvent.name];
    const iconClass =
      latestAppliedProperties[currentEventCategory]?.members[currentEventType]
        .iconClass ?? '';
    const iconColor = getEventColors(
      latestAppliedProperties[currentEventCategory]?.members[
        currentEventType
      ] ?? '',
      currentEvent.alarmDescription,
      currentEvent.operationMode,
      currentEvent.state,
    );
    const currentEventActivity = currentEvent.activity;
    const currentEventModified = {
      mfrType: mfrId,
      time: new Date(currentEvent.time),
      color: 'red', // TODO: get this from the config??
      type: currentEventType,
      alarmDescription: currentEvent.alarmDescription,
      errorType: currentEvent.errorType,
      device: currentEvent.device,
      name: currentEvent.name,
      activity: currentEventActivity,
      craneDirection: currentEvent.craneDirection,
      direction: currentEvent.direction,
      position: currentEvent.position,
      occurrencesLastDay: currentEvent.occurrencesLastDay,
      occurrencesLastHour: currentEvent.occurrencesLastHour,
      numberOfRetries: currentNumberOfRetries,
      tripCode: currentEvent.tripCode,
      frequencyTripType: currentEvent.frequencyTripType,
      tripController: currentEvent.controller,
      tripDescription: currentEvent.description,
      operationState: currentEvent.operationState,
      operationMode: currentEvent.operationMode,
      powerLossReason: currentEvent.powerLossReason,
      state: currentEvent.state,
      process: currentEvent.process,
      emergencyButton: currentEvent.emergencyButton,
      gridPower: currentEvent.gridPower,
      kitchenFence1: currentEvent.kitchenFence1,
      kitchenRelay: currentEvent.kitchenRelay,
      mainRelay: currentEvent.mainRelay,
      login: currentEvent.login,
      iconClass,
      iconColor,
      code: undefined,
      endTime: new Date(),
      parameters: undefined,
    };
    if (currentEventActivity) {
      currentEventModified.code = currentEventActivity.alarmCode;
      currentEventModified.endTime = new Date(currentEventActivity.endTime);
      currentEventModified.parameters = currentEventActivity.alarmParameters;
    } else {
      currentEventModified.endTime = addSeconds(new Date(currentEvent.time), 1);
    }
    if (!isNull(currentEvent.name) && currentEvent.type !== 'Unknown') {
      eventsObject[currentEventCategory][currentEventType].push(
        currentEventModified,
      );
    }
  });
  return eventsObject;
};

export const getCircularChartDataFromSelectedActivityData = (
  selectedActivityData,
  weightValueKey,
  vectorSettings,
) => {
  const { loadResult } = selectedActivityData;
  const circularChartData = loadResult.map((currentLoadResult) => {
    return [
      vectorSettings.feedNames[currentLoadResult.feedId],
      currentLoadResult[weightValueKey],
    ];
  });
  return circularChartData;
};

export const getActivityColorsConfigDictionaryFromMfrChartObject = (
  backendData,
) => {
  const activityColorsDictionary = {};
  backendData.chartData.mfr1Activities.forEach((currentActivityData) => {
    activityColorsDictionary[currentActivityData.name] =
      currentActivityData.color;
  });
  backendData.chartData.mfr2Activities.forEach((currentActivityData) => {
    if (!activityColorsDictionary[currentActivityData.name]) {
      activityColorsDictionary[currentActivityData.name] =
        currentActivityData.color;
    }
  });
  backendData.chartData.fgActivities.forEach((currentActivityData) => {
    if (!activityColorsDictionary[currentActivityData.name]) {
      activityColorsDictionary[currentActivityData.name] =
        currentActivityData.color;
    }
  });
  return activityColorsDictionary;
};

export const getSettingsFromMfrChartObject = (backendData) => {
  return backendData.chartData.settings;
};

export const convertStartAndEndTimesToDatesFromActivities = (
  activitiesData,
) => {
  return activitiesData.map((currentActivity) => {
    currentActivity.time = currentActivity.time
      ? new Date(currentActivity.time)
      : null;
    currentActivity.endTime = currentActivity.endTime
      ? new Date(currentActivity.endTime)
      : null;
    return currentActivity;
  });
};

const MIN_WEIGHT_FOR_PDB_ACTIVITIES = 0.2;
// TODO: this one doesn't simply calculate weight
// it also does some additional things like
// converting startDate and endDate to JavaScript Date objects
// so that specific bit of logic can be extracted out of it in
// order to reduce the source code of this file a bit
export const calculateMaxWeightForPdbActivities = (activitiesData) => {
  let maxDispensedWeightFromData = MIN_WEIGHT_FOR_PDB_ACTIVITIES;
  let result = activitiesData.map((currentActivity) => {
    currentActivity.time = currentActivity.time
      ? new Date(currentActivity.time)
      : null;
    currentActivity.endTime = currentActivity.endTime
      ? new Date(currentActivity.endTime)
      : null;
    if (currentActivity.activity) {
      if (currentActivity.activity.dispensedWeight) {
        // presumed loading activity
        if (
          currentActivity.activity.dispensedWeight > maxDispensedWeightFromData
        ) {
          maxDispensedWeightFromData = currentActivity.activity.dispensedWeight;
        }
      }
    }
    return currentActivity;
  });
  result = activitiesData.map((currentActivity) => {
    if (currentActivity.activity) {
      currentActivity.maxDispensedWeight = maxDispensedWeightFromData;
    }
    return currentActivity;
  });
  return result;
};

export const getActivitiesFromPdbObject = (backendData: VectorDataState) => {
  let pdbActivities: ReturnType<
    typeof getActivitiesFromBackendResponseActivityTypeObject<PdbActivities>
  > = [];
  backendData.chartData.pdbActivities.forEach((currentActivityData) => {
    const extractedActivities =
      getActivitiesFromBackendResponseActivityTypeObject<PdbActivities>(
        currentActivityData,
        'PDB',
      );
    pdbActivities = pdbActivities.concat(
      extractedActivities,
    ) as typeof extractedActivities;
  });
  pdbActivities = calculateMaxWeightForPdbActivities(pdbActivities);
  return pdbActivities;
};

export const getActivitiesFromFeedGrabberObject = (
  backendData,
  vectorSettings,
) => {
  let allActivities: any = [];
  backendData.chartData.fgActivities.forEach((currentActivityData) => {
    allActivities = allActivities.concat(
      getActivitiesFromBackendResponseActivityTypeObject(
        currentActivityData,
        'feedGrabber',
      ),
    );
  });
  allActivities = convertStartAndEndTimesToDatesFromActivities(allActivities);
  const differentThanGrabActivities: any = [];
  const grabActivities: any = [];
  const grabSubactivities: any = [];
  const craneMovingActivities: any = [];
  const feedHeightActivities: any = [];
  let feedHeightMaxWeight = 0;
  const feedIdToColorDictionary = {};
  allActivities.forEach((currentActivity) => {
    const currentGrabTaskFeedId = currentActivity?.activity?.feedId;
    const feedName = vectorSettings?.feedNames[currentGrabTaskFeedId];

    if (currentActivity.name === 'Grab request') {
      currentActivity.feedName = feedName;
    }
    if (currentActivity.name === 'Grab task') {
      currentActivity.feedName = feedName;
      grabActivities.push(currentActivity);

      currentActivity.activity.scans.forEach((currentScanData) => {
        if (currentScanData.startTime) {
          grabSubactivities.push({
            time: new Date(currentScanData.startTime),
            startTime: new Date(currentScanData.startTime),
            endTime: new Date(currentScanData.endTime),
            color: FEED_GRABBER_SCANNING_COLOR,
            data: currentScanData,
            grabTaskData: currentActivity,
            subactivityId: 'Scanning',
            mfrType: 'feedGrabber',
          });
        }
      });
      currentActivity.activity.grabs.forEach((currentGrabData) => {
        if (currentGrabData.startTime) {
          grabSubactivities.push({
            time: new Date(currentGrabData.startTime),
            startTime: new Date(currentGrabData.startTime),
            endTime: new Date(currentGrabData.endTime),
            color: FEED_GRABBER_GRABBING_COLOR[currentGrabData?.grapType],
            data: currentGrabData,
            grabTaskData: currentActivity,
            subactivityId: 'Grabbing',
            mfrType: 'feedGrabber',
          });
        }
      });
      if (
        currentActivity.activity.dump &&
        currentActivity.activity.dump.startTime
      ) {
        grabSubactivities.push({
          time: new Date(currentActivity.activity.dump.startTime),
          startTime: new Date(currentActivity.activity.dump.startTime),
          endTime: new Date(currentActivity.activity.dump.endTime),
          color: FEED_GRABBER_DUMPING_COLOR,
          data: currentActivity.activity.dump,
          grabTaskData: currentActivity,
          subactivityId: 'Dumping',
          mfrType: 'feedGrabber',
        });
      }

      const currentGrabTaskDumpWeight = currentActivity.activity.dump
        ? currentActivity.activity.dump.dumpedWeight
        : 0;
      feedHeightActivities.push({
        weight: currentGrabTaskDumpWeight,
        dumpedWeight: `${currentGrabTaskDumpWeight / 1000} kg`,
        time: currentActivity.time,
        startTime: currentActivity.time,
        endTime: currentActivity.endTime,
        feedId: currentGrabTaskFeedId,
        feedName,
        name: 'Grab task',
        mfrType: 'feedGrabber',
        isFeedHeightActivity: true,
      });
      if (!feedIdToColorDictionary[currentGrabTaskFeedId]) {
        feedIdToColorDictionary[currentGrabTaskFeedId] = getRandomHEXColor();
      }
      if (currentGrabTaskDumpWeight > feedHeightMaxWeight) {
        feedHeightMaxWeight = currentGrabTaskDumpWeight;
      }
    } else if (currentActivity.device === 'Bc') {
      craneMovingActivities.push(currentActivity);
    } else {
      differentThanGrabActivities.push(currentActivity);
    }
  });

  return {
    differentThanGrabActivities,
    craneMovingActivities,
    grabActivities,
    grabSubactivities,
    feedHeightActivities,
    feedHeightMaxWeight,
    feedIdToColorDictionary,
  };
};

export const getSubactivityObjectsByGrabTask = (
  grabTaskData: GrabTaskDetails,
) => {
  const events: (
    | GrabTaskDetails
    | GrabSubactivtyTypes
    | (GrabTaskDetails & GrabSubactivtyTypes)
  )[] = [];
  events.push(grabTaskData);
  if (grabTaskData.activity.scans && grabTaskData.activity.scans.length > 0) {
    const newScanEvent: GrabSubactivtyDetails<'Scanning'> = {
      grabTaskData,
      subactivityId: 'Scanning',
      mfrType: 'feedGrabber',
      startTime: undefined,
      endTime: undefined,
    };
    events.push(newScanEvent);
  }
  if (grabTaskData.activity.grabs && grabTaskData.activity.grabs.length > 0) {
    const newGrabEvent: GrabSubactivtyDetails<'Grabbing'> = {
      grabTaskData,
      subactivityId: 'Grabbing',
      mfrType: 'feedGrabber',
      startTime: undefined,
      endTime: undefined,
    };
    events.push(newGrabEvent);
  }
  if (grabTaskData.activity.dump && grabTaskData.activity.dump.startTime) {
    const grabberDumpEventData = grabTaskData.activity.dump;
    const newDumpEvent: GrabSubactivtyDetails<'Dumping'> = {
      startTime: grabberDumpEventData.startTime,
      endTime: grabberDumpEventData.endTime,
      grabTaskData,
      subactivityId: 'Dumping',
      mfrType: 'feedGrabber',
    };
    events.push(newDumpEvent);
  }

  return events;
};

function isActivityLoadingScan(
  activity: Exclude<MFRActivities, BaseActivitySpan>['activity'],
): activity is MFRLoadingActivitySpan['activity'] {
  return 'loadedWeight' in activity;
}
function isActivitySpanCriticalAlarm(
  activitySpanData: ActivitySpan<any>,
): activitySpanData is CriticalAlarmActivitySpan {
  return activitySpanData.activity.alarmCode !== null;
}

export const getActivitiesFromBackendResponseActivityTypeObject = <
  T extends Activity,
>(
  activityTypeObject: ActivityType<T>,
  mfrId: string,
) => {
  return activityTypeObject.activitySpans.map((currentActivitySpanData) => {
    const newActivityObject: ExtendedActivityObject<T> = {
      time: new Date(currentActivitySpanData.startTime),
      endTime: currentActivitySpanData.endTime
        ? new Date(currentActivitySpanData.endTime)
        : null,
      name: activityTypeObject.name,
      device: currentActivitySpanData.device,
      mfrType: mfrId,
      activity: {
        ...currentActivitySpanData['activity'],
        accuracyPercentage: null,
      },
      color: activityTypeObject.color,
      code: null,
      parameters: null,
      feedTask: null,
      drivingRouteTask: null,
      maxLoadedWeight: null,
      convertedCalibrationTime: null,
    };

    if (
      isActivityCriticalAlarm(activityTypeObject) &&
      isActivitySpanCriticalAlarm(currentActivitySpanData)
    ) {
      newActivityObject.color = activityTypeObject.color;
      newActivityObject.code = currentActivitySpanData.activity.alarmCode;
      newActivityObject.parameters =
        currentActivitySpanData.activity.alarmParameters;
    }
    if (activityTypeObject.name === 'Moving') {
      if (currentActivitySpanData.convertedCalibrationTime) {
        newActivityObject.color = FEED_GRABBER_CALIBRATION_COLOR;
        newActivityObject.convertedCalibrationTime =
          currentActivitySpanData.convertedCalibrationTime;
      }
    }
    if ('feedTask' in currentActivitySpanData) {
      newActivityObject.feedTask = currentActivitySpanData.feedTask;
    }

    if ('drivingRouteTask' in currentActivitySpanData) {
      newActivityObject.drivingRouteTask =
        currentActivitySpanData.drivingRouteTask;
    }
    return newActivityObject;
  });
};

export const getActivitiesFromMfrChartObject = (
  chartObject: { chartData: VectorChartData },
  mfrId: 'mfr1' | 'mfr2',
  filterDataFn: (activity: ActivityType<MFRActivities>) => boolean,
) => {
  const { chartData } = chartObject;
  let allActivities: ReturnType<
    typeof getActivitiesFromBackendResponseActivityTypeObject<MFRActivities>
  > = [];

  chartData[`${mfrId}Activities`].forEach((currentActivityData) => {
    if (filterDataFn(currentActivityData)) {
      allActivities = allActivities.concat(
        getActivitiesFromBackendResponseActivityTypeObject<MFRActivities>(
          currentActivityData,
          mfrId,
        ),
      );
    }
  });

  const MIN_LOADED_WEIGHT = 0.2;
  let maxLoadedWeightFromData = MIN_LOADED_WEIGHT;
  allActivities = allActivities.map((currentActivity) => {
    // activity should be erroring as well
    if (
      currentActivity.activity &&
      isActivityLoadingScan(currentActivity.activity)
    ) {
      if (currentActivity.activity.loadedWeight > maxLoadedWeightFromData) {
        maxLoadedWeightFromData = currentActivity.activity.loadedWeight;
      }

      currentActivity.activity.accuracyPercentage =
        calculateAccuracy(currentActivity);
    }
    return currentActivity;
  });

  allActivities = allActivities.map((currentActivity) => {
    if (currentActivity.activity) {
      currentActivity.maxLoadedWeight = maxLoadedWeightFromData;
    }
    return currentActivity;
  });
  return allActivities;
};
