import { put, take, call, fork, all, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { UPDATE_DATA_CHART_REQUESTED } from '../types/dataChartTypes';
import {
  getDataKeyChartDataSuccess,
  getDataKeyChartDataError,
  resetZoomDomain,
  resetChartsData,
} from '../actions/dataChartActions';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import {
  DATA_MIDDLE_CHART_ID,
  DATA_TOP_CHART_ID,
  GOOGLE_ANALYTICS_DATA_EXPLORER_APPLY,
} from '../../../common/constants';
import {
  getChartTypeByChartName,
  getChartUnitByChartName,
} from '../../../common/data/new_charts_page_metadata';
import { dataChartConfigSelect } from '../../../selectors/data_chart_config';
import { setGoogleAnalyticTracking } from '../../../common/redux/googleAnalyticTracking/actions';
import {
  getAstronautFilteredData,
  getAstronautLinkedFilteredData,
  getAstronautSelectedProperties,
  getCurrentTimeRange,
  setAstronautLatestAppliedProperties,
  setAstronautSelectedProperties,
} from '../../../reducers/astronautPropertySelectSlice';
import { initialDataExplorerLoadManager } from '../../../reducers/dataExplorerLoadManagerSlice';
import {
  compareArraysValues,
  getSearchParams,
  updateSearchParams,
} from '../../../common/utils';
import { getSelectedDisabledFields } from '../../AstronautDataExplorer/AstronautFilterMenu/utils';

let currentLoadId = 0;

export default function* dataChartSaga(api) {
  while (true) {
    const updateDataChartRequestedAction = yield take(
      UPDATE_DATA_CHART_REQUESTED,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield put(resetChartsData());
    const { entityId, startDate, endDate, linkedEntityId, replace, navigate } =
      updateDataChartRequestedAction.payload;
    const dataChartConfigInLocalStorage = yield select(dataChartConfigSelect);
    const dataKeysDictionary = yield select(getAstronautSelectedProperties);
    const currentDataRange = yield select(getCurrentTimeRange);
    const filteredData = yield select(getAstronautFilteredData);
    const linkedFilteredData = yield select(getAstronautLinkedFilteredData);

    const { entityInfo: currentRobot } = yield select(
      (state) => state.entityInfo,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const currentRobotName = currentRobot?.name?.replace('astronaut-', '');

    const disabledValues = getSelectedDisabledFields(
      filteredData || [],
      dataKeysDictionary,
      'dataLocationInStore',
    );

    const disabledValuesLinked = getSelectedDisabledFields(
      linkedFilteredData?.configurations || [],
      dataKeysDictionary,
      'dataLocationInStore',
      linkedFilteredData?.linkedName,
    );

    const topChartDataKeys = [];
    const middleChartDataKeys = [];
    if (!isEmpty(dataKeysDictionary)) {
      Object.keys(dataKeysDictionary).forEach((currentDataKey) => {
        // test to see if currentDataKey is with linked value of the current robot
        if (currentDataKey.includes(currentRobotName)) {
          return;
        }

        const regex = /\s\(\d+\)/g;
        const removedRemovedRobotName = currentDataKey.replace(regex, '');

        if (
          currentDataRange === 'Day' &&
          (disabledValues?.includes(removedRemovedRobotName) ||
            disabledValuesLinked?.includes(removedRemovedRobotName))
        ) {
          // when the user is coming from an empty URL and he has disabled day fields in his local storage from previous sessions
          return;
        }

        if (dataKeysDictionary[currentDataKey] === DATA_TOP_CHART_ID) {
          topChartDataKeys.push(currentDataKey);
        } else if (
          dataKeysDictionary[currentDataKey] === DATA_MIDDLE_CHART_ID
        ) {
          middleChartDataKeys.push(currentDataKey);
        }
      });
    }

    const updatedDataKeysDictionary = {
      ...topChartDataKeys.reduce(
        (prev, next) => ({ ...prev, [next]: '1' }),
        {},
      ),
      ...middleChartDataKeys.reduce(
        (prev, next) => ({ ...prev, [next]: '2' }),
        {},
      ),
    };

    yield put(setAstronautLatestAppliedProperties(updatedDataKeysDictionary));
    yield put(setAstronautSelectedProperties(updatedDataKeysDictionary));
    // update active graphs
    localStorage.updateObjectProperty(
      'adeConfig',
      'activeGraphs',
      updatedDataKeysDictionary,
    );

    const chartsData = [];

    chartsData.push({
      chartId: DATA_TOP_CHART_ID,
      dataKeys: topChartDataKeys,
    });

    chartsData.push({
      chartId: DATA_MIDDLE_CHART_ID,
      dataKeys: middleChartDataKeys,
    });

    if (navigate) {
      chartsData.forEach((currentChart) => {
        const currentParamsForChartId = getSearchParams(currentChart.chartId);
        if (
          !currentParamsForChartId ||
          (currentParamsForChartId &&
            !compareArraysValues(
              currentParamsForChartId.split(','),
              currentChart.dataKeys,
            ))
        ) {
          updateSearchParams(
            currentChart.chartId,
            currentChart.dataKeys,
            replace,
            navigate,
          );
        }
      });
    }

    yield put(
      initialDataExplorerLoadManager({
        chartsData: chartsData.filter((chart) => chart.dataKeys.length !== 0),
        dataChartConfigInLocalStorage,
      }),
    );
    yield put(resetZoomDomain());
    const selectedChartTopics = JSON.stringify(updatedDataKeysDictionary)
      .replace(/{/gi, '')
      .replace(/}/gi, '');
    yield put(
      setGoogleAnalyticTracking(
        GOOGLE_ANALYTICS_DATA_EXPLORER_APPLY,
        selectedChartTopics,
        `${startDate.toString()}-${endDate.toString()}`,
      ),
    );

    yield all(
      chartsData.map((currentChart) => {
        if (currentChart.dataKeys.length === 0) return;

        return fork(
          getChartData,
          api,
          entityId,
          linkedFilteredData?.linkedName,
          startDate,
          endDate,
          currentChart.dataKeys,
          linkedEntityId,
          currentChart.chartId,
          dataChartConfigInLocalStorage,
        );
      }),
    );
    // #endregion
  }
}

/** Create requests to get chart data for a specific chart
 * @param {string} entityId - entity id
 * @param {Date} startDate - Start date of the data range
 * @param {Date} endDate - Start date of the data range
 * @param {Array} dataKeys - Array of data keys of interest to the end user/that they want to see visualized in a chart
 * @param {String} linkedEntityId - Id of the selected linked element
 * @param {string} chartId - Chart id
 * @param {Object} dataChartConfigInLocalStorage - Object that provides answer to the question "In what kind of chart (line/status/spline) is this metric displayed?"
 */
function* getChartData(
  api,
  entityId,
  linkedName,
  startDate,
  endDate,
  dataKeys,
  linkedEntityId,
  chartId,
  dataChartConfigInLocalStorage,
) {
  function getModifiedDataFromBackend(backendData) {
    backendData.forEach((currentChartValue, currentChartIndex) => {
      backendData[currentChartIndex].type = getChartTypeByChartName(
        currentChartValue.name,
        dataChartConfigInLocalStorage,
      );
      backendData[currentChartIndex].unit = getChartUnitByChartName(
        currentChartValue.name,
        dataChartConfigInLocalStorage,
      );
    });
    return backendData;
  }

  try {
    const chartData = yield call(
      api.getChartDetails,
      entityId,
      startDate,
      endDate,
      dataKeys,
      linkedEntityId,
    );
    getModifiedDataFromBackend(chartData.data);

    chartData.data.forEach((data) => {
      const linkedGraph = data.graphs.find(
        // find the graph that belongs to the linked robot
        (graph) => graph.entityId === linkedEntityId,
      );

      if (linkedGraph) {
        // Add the linked robot number to the graph
        linkedGraph.name = `${linkedGraph.name} (${linkedName})`;
      }
    });

    yield put(
      getDataKeyChartDataSuccess(
        chartData.data,
        startDate,
        endDate,
        chartId,
        linkedEntityId,
      ),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getDataKeyChartDataError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
