import { useEffect, useState } from 'react';

function ErrorBoundary({ children }) {
  const [isThereError, setIsThereError] = useState(false);

  useEffect(() => {
    const handleError = (event) => {
      if (
        event.reason.name === 'ChunkLoadError' ||
        event.message.includes('because its MIME type')
      ) {
        setIsThereError(true);
      }
    };

    window.addEventListener('unhandledrejection', handleError);
    return () => {
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

  if (isThereError) {
    window.location.reload();
    return null;
  }
  return children;
}

export default ErrorBoundary;
