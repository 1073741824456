import React from 'react';
import type { TableTypes } from '../../common/types';
import Icon from '../../common/components/IconSvg/Icon';

type Props = {
  isSortedByColumn: boolean;
  direction: TableTypes.SortingObject['direction'];
};

function SortingIcon({ isSortedByColumn, direction }: Props) {
  return (
    <button className="sorting-icons">
      <Icon
        className={`desc ${
          isSortedByColumn && direction === 'asc' && 'active'
        }`}
        name="lely-icon-caret-down"
        style={{
          position: 'absolute',
          top: '4px',
          width: '16px',
          height: '16px',
        }}
      />
      <Icon
        className={`asc ${
          isSortedByColumn && direction === 'desc' && 'active'
        }`}
        name="lely-icon-caret-down"
        style={{
          position: 'absolute',
          bottom: '4px',
          width: '16px',
          height: '16px',
        }}
      />
    </button>
  );
}

export default SortingIcon;
