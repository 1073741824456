import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  filterFeedIoDispenserConfiguration,
  getNormalizedConfiguration,
  getNormalizedRobotVersionsForZoomRange,
} from '../components/Vector/DetailsLayouts/Robots/util';
import { RootState } from './rootReducer';
import type {
  VectorDetailsInitialState,
  VectorSettings,
  MfrConfig,
  FgConfig,
  FgNxpConfig,
  BcConfig,
  FeedIoDispenserConfig,
  SalsaPDBConfig,
} from './types/vectorDetailsTypes';
import { ZoomRange } from './zoomSlice';

export const vectorDetailsInitialState: VectorDetailsInitialState = {
  vectorSettings: null,
  selectedEventsData: [],
  selectedItemDetails: {},
  hoveredCircleElement: '',
};

const vectorDetailsSlice = createSlice({
  name: 'vectorDetails',
  initialState: vectorDetailsInitialState,
  reducers: {
    setVectorSettings(state, action) {
      state.vectorSettings = action.payload;
    },
    setSelectedEventsData(state, action) {
      state.selectedEventsData = action.payload;
    },
    setSelectedItemDetails(state, action) {
      state.selectedItemDetails = action.payload;
    },
    setHoveredCircleElement(state, action: PayloadAction<string>) {
      state.hoveredCircleElement = action.payload;
    },
    resetVectorDetails: () => vectorDetailsInitialState,
  },
});

export const {
  setVectorSettings,
  setSelectedEventsData,
  setSelectedItemDetails,
  resetVectorDetails,
  setHoveredCircleElement,
} = vectorDetailsSlice.actions;

export const getHoveredCircleElement = (state: RootState) =>
  state.vectorDetails.hoveredCircleElement;

export const getVectorSettings = (state: RootState): VectorSettings => {
  if (state.vectorDetails.vectorSettings) {
    return state.vectorDetails.vectorSettings;
  }

  return {} as VectorSettings;
};

export const getFeedNames = (state: RootState): Record<string, string> => {
  if (state.vectorDetails.vectorSettings) {
    return state.vectorDetails.vectorSettings.feedNames;
  }
  return {};
};

export const getSelectedEventsData = (state: RootState) =>
  state.vectorDetails.selectedEventsData;
export const getSelectedItemDetails = (state: RootState) =>
  state.vectorDetails.selectedItemDetails;
export const selectMfrConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,

  id: 'Mfr1' | 'Mfr2',
): MfrConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings[`${id.toLowerCase()}Config`] && zoomRange) {
    const res = getNormalizedConfiguration(
      settings[`${id.toLowerCase()}Config`],
      zoomRange,
      'MFR',
    );
    return res;
  }
  return [];
};

export const selectFgConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,
): FgConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.fgConfig && zoomRange) {
    const res = getNormalizedConfiguration(settings.fgConfig, zoomRange, 'FG');
    return res;
  }
  return [];
};

export const selectFgNxpConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,
): FgNxpConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.fgNxpConfig && zoomRange) {
    const res = getNormalizedConfiguration(
      settings.fgNxpConfig,
      zoomRange,
      'FG NXP',
    );
    return res;
  }
  return [];
};

export const selectBcConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,
): BcConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.bcConfig && zoomRange) {
    const res = getNormalizedConfiguration(settings.bcConfig, zoomRange, 'BC');
    return res;
  }
  return [];
};

export const selectFeedIoDispenserConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,
): FeedIoDispenserConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.feedIoDispenserConfig && zoomRange) {
    const normalizedConfig = getNormalizedConfiguration(
      settings.feedIoDispenserConfig,
      zoomRange,
      'PDB',
    );
    const res = filterFeedIoDispenserConfiguration(normalizedConfig);
    return res;
  }
  return [];
};

export const selectSalsaPDBConfiguration = (
  state: RootState,
  zoomRange: ZoomRange | null,
): SalsaPDBConfig[] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.pdbConfig && zoomRange) {
    const res = getNormalizedConfiguration(
      settings.pdbConfig,
      zoomRange,
      'PDB',
    );
    return res;
  }
  return [];
};

export const getRobotVersionsForZoomRange = (
  state: RootState,
  robot: 'Mfr1' | 'Mfr2' | 'Pdb' | 'Fg',
  zoomRange: ZoomRange | null,
): string[][] => {
  const settings = state.vectorDetails.vectorSettings;
  if (settings && settings.deviceVersions && zoomRange) {
    return getNormalizedRobotVersionsForZoomRange(
      settings.deviceVersions,
      robot,
      zoomRange,
    );
  }
  return [];
};

export default vectorDetailsSlice.reducer;
