/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import './AppSidebar.scss';
import UserDialog from './UserDialog';
import { useAppSelector } from '../../../reducers/rootReducer';
import AccountButton from './AccountButton';
import DownButtons from './DownButtons';
import { featureFlagsSelectors } from '../../../reducers/featureFlagSlice';
import {
  ADE_ONLINE_UPDATES_FEATURE_FLAG,
  AMF_MENU_OPTIONS,
  DIAGNOSTIC_PAGE,
  VECTOR_EVENTS_PAGE,
} from '../../constants';
import { UserRolesType } from '../../../components/LoginForm/types';
import useUserRole from '../../customHooks/useUserRole';
import { getAstronautMenuOptions, getVectorMenuOptions } from './utils';
import RobotMenuButtons from './RobotMenuButtons';

interface CustomWindow extends Window {
  srv?: any; // Define the srv property as optional
}

declare let window: CustomWindow;
function AppSidebar(): JSX.Element | null {
  const isDiagnosticPageActive = useAppSelector((state) =>
    featureFlagsSelectors.isFlagActive(state, DIAGNOSTIC_PAGE),
  );
  const isEngineer = useUserRole(UserRolesType.Engineer);
  const isUpdatesPageActive = useAppSelector((state) =>
    featureFlagsSelectors.isFlagActive(state, ADE_ONLINE_UPDATES_FEATURE_FLAG),
  );
  const isActiveVectorEventsPage = useAppSelector((state) =>
    featureFlagsSelectors.isFlagActive(state, VECTOR_EVENTS_PAGE),
  );
  const { entityInfo } = useAppSelector((state) => state.entityInfo);
  const url = window.location.pathname;
  const entityId =
    url.toString().indexOf('entities/') !== -1 && url.split('/')[2];
  const entityType = entityId && entityInfo?.type;

  const [open, setOpen] = useState<boolean>(false);
  const handleToggle = (): void => {
    setOpen((prevOpenState) => !prevOpenState);
  };
  const isLoginPage = useMatch('/login');
  const isNotFoundPage = useMatch('/notFound');

  useEffect(() => {
    return () => {
      if (window.srv) {
        window.srv.closeModal();
      }
    };
  }, []);

  const menuOptions = {
    VectorSystem: getVectorMenuOptions(isActiveVectorEventsPage),
    AutomaticMilkFilter: AMF_MENU_OPTIONS,
    AstronautA5: getAstronautMenuOptions(
      isEngineer,
      isDiagnosticPageActive,
      isUpdatesPageActive,
    ),
  };

  if (isLoginPage || isNotFoundPage) return null;

  return (
    <div className=" sidebar__bar  sidebar-fixed-wrapper app-sidebar hoverable">
      <div className="sidebar__navlist borderdown">
        <AccountButton onClickFunction={handleToggle} />
        <RobotMenuButtons
          entityId={entityId}
          optionsArray={menuOptions[entityType]}
        />
        <DownButtons />
      </div>
      <UserDialog open={open} setOpen={setOpen} />
    </div>
  );
}

export default AppSidebar;
