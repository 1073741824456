import React, { ReactElement } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import './Header.scss';
import FarmDetailsBreadCrumb from '../FarmDetails/FarmDetailBreadCrumb';
import BreadCrumb from '../../common/components/Breadcrumb/Breadcrumb';
import FarmLocalTimezone from '../FarmLocalTimezone/FarmLocalTimezone';
import SettingsTabs from '../../containers/Settings/SettingsTabs';
import EntityStatus from '../EntityState/EntityState';
import { ReactComponent as LelyLogo } from './logo-lely.svg';
import { useAppSelector } from '../../reducers/rootReducer';
import Icon from '../../common/components/IconSvg/Icon';
/** Header component */
function Header(): JSX.Element | null {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userSession);

  /** The main function which contains route navigation logic and from where app start */
  const { farmDetails } = useAppSelector((state) => state.farmDetails);

  /** URL */
  const url = window.location.pathname;
  const isUserOnHelpPage = url.indexOf('help') !== -1;
  const isUserOnFarmDetailsPage = url.indexOf('/farms/') !== -1;
  const isUserOnEntityPage = url.indexOf('/entities') !== -1;
  const isSettingsPage = useMatch('/entities/:externalId/settings');
  const isBackupsPage = useMatch('/entities/:externalId/settings/backups');
  const isAMFSettingsPage = useMatch('/entities/:externalId/AMF-settings');

  const isUserOnSettingsPage =
    isSettingsPage || isBackupsPage || isAMFSettingsPage;
  let breadcrumbComponent: ReactElement<any, any>;
  let entityStatusAndLocalTimezoneComponents: ReactElement[] = [];

  if (isUserOnHelpPage) {
    breadcrumbComponent = <FarmDetailsBreadCrumb farmName="Help" />;
    entityStatusAndLocalTimezoneComponents = [
      <FarmLocalTimezone key="farmlcl" farmDetails={farmDetails} />,
    ];
  } else if (isUserOnFarmDetailsPage) {
    breadcrumbComponent = (
      <FarmDetailsBreadCrumb farmName={farmDetails.farmName} />
    );
    entityStatusAndLocalTimezoneComponents = [
      <FarmLocalTimezone key="farmlcl" farmDetails={farmDetails} />,
    ];
  } else if (isUserOnEntityPage) {
    entityStatusAndLocalTimezoneComponents = [
      <EntityStatus key="entityst" />,
      <FarmLocalTimezone key="farmlcl" farmDetails={farmDetails} />,
    ];
    breadcrumbComponent = (
      <div className="settings-breadcrumb-container">
        <BreadCrumb />
        {isUserOnSettingsPage && <SettingsTabs />}
      </div>
    );
  } else {
    breadcrumbComponent = (
      <div className="settings-breadcrumb-container">
        <BreadCrumb />
        {isUserOnSettingsPage && <SettingsTabs />}
      </div>
    );
  }

  return isLoggedIn ? (
    <div className="top-navigation-fixed-wrapper" id="app-header">
      <div className="top-navigation" data-testid="div-header">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate('/farms');
          }}
          className="top-navigation__logo"
        >
          <LelyLogo />
          <span className="top-navigation__logotext">
            Technician Diagnostic Assistant
          </span>
        </a>
        {breadcrumbComponent}
        <div className="top-navigation__inner">
          <div className="top-navigation__btns d-none d-sm-flex">
            {entityStatusAndLocalTimezoneComponents}
            <button className="btn btn-secondary" type="button">
              <Icon
                name="lely-icon-notifications"
                style={{ width: 18, height: 18 }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
export default Header;
