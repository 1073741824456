// src/icons/index.js

// Import statements
import { ReactComponent as Icon3gMobiledata } from './3g-mobiledata.svg';
import { ReactComponent as Icon4gMobiledata } from './4g-mobiledata.svg';
import { ReactComponent as Icon5gMobiledata } from './5g-mobiledata.svg';
import { ReactComponent as IconAbortion } from './abortion.svg';
import { ReactComponent as IconAccountBalance } from './account-balance.svg';
import { ReactComponent as IconAccount } from './account.svg';
import { ReactComponent as IconActivities } from './activities.svg';
import { ReactComponent as IconAddFile } from './add-file.svg';
import { ReactComponent as IconAlarm } from './alarm.svg';
import { ReactComponent as IconApps } from './apps.svg';
import { ReactComponent as IconArchive } from './archive.svg';
import { ReactComponent as IconArrowDown } from './arrow-down.svg';
import { ReactComponent as IconArrowLeft } from './arrow-left.svg';
import { ReactComponent as IconArrowRight } from './arrow-right.svg';
import { ReactComponent as IconArrowUp } from './arrow-up.svg';
import { ReactComponent as IconAstronaut } from './astronaut.svg';
import { ReactComponent as IconBackup } from './backup.svg';
import { ReactComponent as IconBarChart } from './bar-chart.svg';
import { ReactComponent as IconBarGraph } from './bar-graph.svg';
import { ReactComponent as IconBarn } from './barn.svg';
import { ReactComponent as IconBattery1Bar } from './battery-1-bar.svg';
import { ReactComponent as IconBattery2Bar } from './battery-2-bar.svg';
import { ReactComponent as IconBatteryCharging } from './battery-charging.svg';
import { ReactComponent as IconBatteryFull } from './battery-full.svg';
import { ReactComponent as IconBlock } from './block.svg';
import { ReactComponent as IconBluetoothConnected } from './bluetooth-connected.svg';
import { ReactComponent as IconBluetoothDisabled } from './bluetooth-disabled.svg';
import { ReactComponent as IconBluetoothSearching } from './bluetooth-searching.svg';
import { ReactComponent as IconBluetooth } from './bluetooth.svg';
import { ReactComponent as IconBugReport } from './bug-report.svg';
import { ReactComponent as IconCalendar } from './calendar.svg';
import { ReactComponent as IconCall } from './call.svg';
import { ReactComponent as IconCardView } from './card-view.svg';
import { ReactComponent as IconCaretDown } from './caret-down.svg';
import { ReactComponent as IconCaretLeft } from './caret-left.svg';
import { ReactComponent as IconCaretRight } from './caret-right.svg';
import { ReactComponent as IconCaretUp } from './caret-up.svg';
import { ReactComponent as IconCash } from './cash.svg';
import { ReactComponent as IconCategory } from './category.svg';
import { ReactComponent as IconCentralUnit } from './central-unit.svg';
import { ReactComponent as IconChargingStation } from './charging-station.svg';
import { ReactComponent as IconChart } from './chart.svg';
import { ReactComponent as IconChatBubble } from './chat-bubble.svg';
import { ReactComponent as IconCheck } from './check.svg';
import { ReactComponent as IconChecklist } from './checklist.svg';
import { ReactComponent as IconChevronLeft } from './chevron-left.svg';
import { ReactComponent as IconChevronRight } from './chevron-right.svg';
import { ReactComponent as IconCircleCheck } from './circle-check.svg';
import { ReactComponent as IconClockInterval } from './clock-interval.svg';
import { ReactComponent as IconClock } from './clock.svg';
import { ReactComponent as IconClose } from './close.svg';
import { ReactComponent as IconCloudBackup } from './cloud-backup.svg';
import { ReactComponent as IconCloudDone } from './cloud-done.svg';
import { ReactComponent as IconCloudDownload } from './cloud-download.svg';
import { ReactComponent as IconCloud } from './cloud.svg';
import { ReactComponent as IconComment } from './comment.svg';
import { ReactComponent as IconComputer } from './computer.svg';
import { ReactComponent as IconCopy } from './copy.svg';
import { ReactComponent as IconCow } from './cow.svg';
import { ReactComponent as IconCreditcard } from './creditcard.svg';
import { ReactComponent as IconDataPoints } from './data-points.svg';
import { ReactComponent as IconDashboard } from './dashboard.svg';
import { ReactComponent as IconDigram } from './digram.svg';
import { ReactComponent as IconDisease } from './disease.svg';
import { ReactComponent as IconDissatisfied } from './dissatisfied.svg';
import { ReactComponent as IconDocument } from './document.svg';
import { ReactComponent as IconDownloadDone } from './download-done.svg';
import { ReactComponent as IconDownload } from './download.svg';
import { ReactComponent as IconDrag } from './drag.svg';
import { ReactComponent as IconDriver } from './driver.svg';
import { ReactComponent as IconDryOff } from './dry-off.svg';
import { ReactComponent as IconEast } from './east.svg';
import { ReactComponent as IconEdit } from './edit.svg';
import { ReactComponent as IconEmotions } from './emotions.svg';
import { ReactComponent as IconEngine } from './engine.svg';
import { ReactComponent as IconError } from './error.svg';
import { ReactComponent as IconExport } from './export.svg';
import { ReactComponent as IconFarm } from './farm.svg';
import { ReactComponent as IconFeed } from './feed.svg';
import { ReactComponent as IconFence } from './fence.svg';
import { ReactComponent as IconFetchCow } from './fetch-cow.svg';
import { ReactComponent as IconFilter } from './filter.svg';
import { ReactComponent as IconFingerprint } from './fingerprint.svg';
import { ReactComponent as IconFolderOpen } from './folder-open.svg';
import { ReactComponent as IconFolderZip } from './folder-zip.svg';
import { ReactComponent as IconFullscreenExit } from './fullscreen-exit.svg';
import { ReactComponent as IconFullscreen } from './fullscreen.svg';
import { ReactComponent as IconGrabber } from './grabber.svg';
import { ReactComponent as IconGraphDown } from './graph-down.svg';
import { ReactComponent as IconGraphUp } from './graph-up.svg';
import { ReactComponent as IconGraphView } from './graph-view.svg';
import { ReactComponent as IconGraph } from './graph.svg';
import { ReactComponent as IconGrass } from './grass.svg';
import { ReactComponent as IconGridView } from './grid-view.svg';
import { ReactComponent as IconGrid } from './grid.svg';
import { ReactComponent as IconGroupAdd } from './group-add.svg';
import { ReactComponent as IconGroupRemove } from './group-remove.svg';
import { ReactComponent as IconGroup } from './group.svg';
import { ReactComponent as IconHealthReport } from './health-report.svg';
import { ReactComponent as IconHealthTreatment } from './health-treatment.svg';
import { ReactComponent as IconHeat } from './heat.svg';
import { ReactComponent as IconHelp } from './help.svg';
import { ReactComponent as IconHerdMovement } from './herd-movement.svg';
import { ReactComponent as IconHerd } from './herd.svg';
import { ReactComponent as IconHide } from './hide.svg';
import { ReactComponent as IconHistory } from './history.svg';
import { ReactComponent as IconHome } from './home.svg';
import { ReactComponent as IconHotRins } from './hot-rins.svg';
import { ReactComponent as IconHuman } from './human.svg';
import { ReactComponent as IconIncome } from './income.svg';
import { ReactComponent as IconInformation } from './information.svg';
import { ReactComponent as IconInsemination } from './insemination.svg';
import { ReactComponent as IconIrisGroup } from './iris-group.svg';
import { ReactComponent as IconIris } from './iris.svg';
import { ReactComponent as IconKeyboard } from './keyboard.svg';
import { ReactComponent as IconLanguage } from './language.svg';
import { ReactComponent as IconLaptop } from './laptop.svg';
import { ReactComponent as IconLayersClear } from './layers-clear.svg';
import { ReactComponent as IconLayers } from './layers.svg';
import { ReactComponent as IconLink } from './link.svg';
import { ReactComponent as IconListBulletin } from './list-bulletin.svg';
import { ReactComponent as IconList } from './list.svg';
import { ReactComponent as IconLocate } from './locate.svg';
import { ReactComponent as IconLocation } from './location.svg';
import { ReactComponent as IconLock } from './lock.svg';
import { ReactComponent as IconLogin } from './login.svg';
import { ReactComponent as IconLogout } from './logout.svg';
import { ReactComponent as IconM4use } from './m4use.svg';
import { ReactComponent as IconMail } from './mail.svg';
import { ReactComponent as IconManage } from './manage.svg';
import { ReactComponent as IconManure } from './manure.svg';
import { ReactComponent as IconMap } from './map.svg';
import { ReactComponent as IconMedicine } from './medicine.svg';
import { ReactComponent as IconMenu } from './menu.svg';
import { ReactComponent as IconMfrDosingRoller } from './mfr-dosing-roller.svg';
import { ReactComponent as IconMfrFeedDoorClosing } from './mfr-feed-door-closing.svg';
import { ReactComponent as IconMfrFeedDoorOpening } from './mfr-feed-door-opening.svg';
import { ReactComponent as IconMfrOpen } from './mfr-open.svg';
import { ReactComponent as IconMfr } from './mfr.svg';
import { ReactComponent as IconMicrophone } from './microphone.svg';
import { ReactComponent as IconMilkSeperation } from './milk-seperation.svg';
import { ReactComponent as IconMilk } from './milk.svg';
import { ReactComponent as IconMinus } from './minus.svg';
import { ReactComponent as IconMixerMotor } from './mixer-motor.svg';
import { ReactComponent as IconMoreHorizontal } from './more-horizontal.svg';
import { ReactComponent as IconMoreVertical } from './more-vertical.svg';
import { ReactComponent as IconMouse } from './mouse.svg';
import { ReactComponent as IconNetworkWifi } from './network-wifi.svg';
import { ReactComponent as IconNeutral } from './neutral.svg';
import { ReactComponent as IconNorthEast } from './north-east.svg';
import { ReactComponent as IconNorthWest } from './north-west.svg';
import { ReactComponent as IconNorth } from './north.svg';
import { ReactComponent as IconNotificationMute } from './notification-mute.svg';
import { ReactComponent as IconNotificationOn } from './notification-on.svg';
import { ReactComponent as IconNotification } from './notification.svg';
import { ReactComponent as IconNotifications } from './notifications.svg';
import { ReactComponent as IconObjects } from './objects.svg';
import { ReactComponent as IconOngoing } from './ongoing.svg';
import { ReactComponent as IconOpportunities } from './opportunities.svg';
import { ReactComponent as IconPains } from './pains.svg';
import { ReactComponent as IconPan } from './pan.svg';
import { ReactComponent as IconPanel } from './panel.svg';
import { ReactComponent as IconPause } from './pause.svg';
import { ReactComponent as IconPdb } from './pdb.svg';
import { ReactComponent as IconPending } from './pending.svg';
import { ReactComponent as IconPersonApprove } from './person-approve.svg';
import { ReactComponent as IconPersonAssign } from './person-assign.svg';
import { ReactComponent as IconPersonRemove } from './person-remove.svg';
import { ReactComponent as IconPersonUnassign } from './person-unassign.svg';
import { ReactComponent as IconPerson } from './person.svg';
import { ReactComponent as IconPhone } from './phone.svg';
import { ReactComponent as IconPicture } from './picture.svg';
import { ReactComponent as IconPincZoomOut } from './pinc-zoom-out.svg';
import { ReactComponent as IconPinchZoomIn } from './pinch-zoom-in.svg';
import { ReactComponent as IconPlay } from './play.svg';
import { ReactComponent as IconPlus } from './plus.svg';
import { ReactComponent as IconPowerManagement } from './power-management.svg';
import { ReactComponent as IconPower } from './power.svg';
import { ReactComponent as IconPregnancy } from './pregnancy.svg';
import { ReactComponent as IconPrinter } from './printer.svg';
import { ReactComponent as IconQrCodeScanner } from './qr-code-scanner.svg';
import { ReactComponent as IconQrCode } from './qr-code.svg';
import { ReactComponent as IconRedo } from './redo.svg';
import { ReactComponent as IconRefresh } from './refresh.svg';
import { ReactComponent as IconReminder } from './reminder.svg';
import { ReactComponent as IconReports } from './reports.svg';
import { ReactComponent as IconReproductionCheck } from './reproduction-check.svg';
import { ReactComponent as IconReset } from './reset.svg';
import { ReactComponent as IconRobot } from './robot.svg';
import { ReactComponent as IconRotateLeft } from './rotate-left.svg';
import { ReactComponent as IconRotateMap } from './rotate-map.svg';
import { ReactComponent as IconRotateRight } from './rotate-right.svg';
import { ReactComponent as IconRouteLayers } from './route-layers.svg';
import { ReactComponent as IconRoutes } from './routes.svg';
import { ReactComponent as IconRouting } from './routing.svg';
import { ReactComponent as IconSatisfied } from './satisfied.svg';
import { ReactComponent as IconScanner } from './scanner.svg';
import { ReactComponent as IconScatterplot } from './scatterplot.svg';
import { ReactComponent as IconScreenRotation } from './screen-rotation.svg';
import { ReactComponent as IconSearch } from './search.svg';
import { ReactComponent as IconSend } from './send.svg';
import { ReactComponent as IconSensor } from './sensor.svg';
import { ReactComponent as IconSettings } from './settings.svg';
import { ReactComponent as IconShoppingCart } from './shopping-cart.svg';
import { ReactComponent as IconShow } from './show.svg';
import { ReactComponent as IconSignalCellular0Bar } from './signal-cellular-0-bar.svg';
import { ReactComponent as IconSignalCellular2Bar } from './signal-cellular-2-bar.svg';
import { ReactComponent as IconSignalCellular4Bar } from './signal-cellular-4-bar.svg';
import { ReactComponent as IconSignalCellularOff } from './signal-cellular-off.svg';
import { ReactComponent as IconSignalWifiBad } from './signal-wifi-bad.svg';
import { ReactComponent as IconSignalWifiOff } from './signal-wifi-off.svg';
import { ReactComponent as IconSires } from './sires.svg';
import { ReactComponent as IconSortDisabled } from './sort-disabled.svg';
import { ReactComponent as IconSortDown } from './sort-down.svg';
import { ReactComponent as IconSortUp } from './sort-up.svg';
import { ReactComponent as IconSort } from './sort.svg';
import { ReactComponent as IconSouthEast } from './south-east.svg';
import { ReactComponent as IconSouthWest } from './south-west.svg';
import { ReactComponent as IconSouth } from './south.svg';
import { ReactComponent as IconStackedBarChart } from './stacked-bar-chart.svg';
import { ReactComponent as IconStarEmpty } from './star-empty.svg';
import { ReactComponent as IconStarFilled } from './star-filled.svg';
import { ReactComponent as IconStop } from './stop.svg';
import { ReactComponent as IconSwipeDown } from './swipe-down.svg';
import { ReactComponent as IconSwipeLeftAndRight } from './swipe-left-and-right.svg';
import { ReactComponent as IconSwipeLeft } from './swipe-left.svg';
import { ReactComponent as IconSwipeUp } from './swipe-up.svg';
import { ReactComponent as IconTableRow } from './table-row.svg';
import { ReactComponent as IconTable } from './table.svg';
import { ReactComponent as IconTablet } from './tablet.svg';
import { ReactComponent as IconTasks } from './tasks.svg';
import { ReactComponent as IconThermostat } from './thermostat.svg';
import { ReactComponent as IconThumbDown } from './thumb-down.svg';
import { ReactComponent as IconThumbUp } from './thumb-up.svg';
import { ReactComponent as IconTouch } from './touch.svg';
import { ReactComponent as IconTraffic } from './traffic.svg';
import { ReactComponent as IconTransfer } from './transfer.svg';
import { ReactComponent as IconTrash } from './trash.svg';
import { ReactComponent as IconTv } from './tv.svg';
import { ReactComponent as IconUndo } from './undo.svg';
import { ReactComponent as IconUpdate } from './update.svg';
import { ReactComponent as IconUpload } from './upload.svg';
import { ReactComponent as IconValve } from './valve.svg';
import { ReactComponent as IconVersion } from './version.svg';
import { ReactComponent as IconVeryDissatisfied } from './very-dissatisfied.svg';
import { ReactComponent as IconVerySatisfied } from './very-satisfied.svg';
import { ReactComponent as IconViewList } from './view-list.svg';
import { ReactComponent as IconWarning } from './warning.svg';
import { ReactComponent as IconWatch } from './watch.svg';
import { ReactComponent as IconWater } from './water.svg';
import { ReactComponent as IconWest } from './west.svg';
import { ReactComponent as IconWifiOff } from './wifi-off.svg';
import { ReactComponent as IconWifi } from './wifi.svg';
import { ReactComponent as IconYoungStock } from './young-stock.svg';
import { ReactComponent as IconZoomIn } from './zoom-in.svg';
import { ReactComponent as IconZoomOut } from './zoom-out.svg';

const iconComponents = {
  'lely-icon-3g-mobiledata': Icon3gMobiledata,
  'lely-icon-4g-mobiledata': Icon4gMobiledata,
  'lely-icon-5g-mobiledata': Icon5gMobiledata,
  'lely-icon-abortion': IconAbortion,
  'lely-icon-account-balance': IconAccountBalance,
  'lely-icon-account': IconAccount,
  'lely-icon-activities': IconActivities,
  'lely-icon-add-file': IconAddFile,
  'lely-icon-alarm': IconAlarm,
  'lely-icon-apps': IconApps,
  'lely-icon-archive': IconArchive,
  'lely-icon-arrow-down': IconArrowDown,
  'lely-icon-arrow-left': IconArrowLeft,
  'lely-icon-arrow-right': IconArrowRight,
  'lely-icon-arrow-up': IconArrowUp,
  'lely-icon-astronaut': IconAstronaut,
  'lely-icon-backup': IconBackup,
  'lely-icon-bar-chart': IconBarChart,
  'lely-icon-bar-graph': IconBarGraph,
  'lely-icon-barn': IconBarn,
  'lely-icon-battery-1-bar': IconBattery1Bar,
  'lely-icon-battery-2-bar': IconBattery2Bar,
  'lely-icon-battery-charging': IconBatteryCharging,
  'lely-icon-battery-full': IconBatteryFull,
  'lely-icon-block': IconBlock,
  'lely-icon-bluetooth-connected': IconBluetoothConnected,
  'lely-icon-bluetooth-disabled': IconBluetoothDisabled,
  'lely-icon-bluetooth-searching': IconBluetoothSearching,
  'lely-icon-bluetooth': IconBluetooth,
  'lely-icon-bug-report': IconBugReport,
  'lely-icon-calendar': IconCalendar,
  'lely-icon-call': IconCall,
  'lely-icon-card-view': IconCardView,
  'lely-icon-caret-down': IconCaretDown,
  'lely-icon-caret-left': IconCaretLeft,
  'lely-icon-caret-right': IconCaretRight,
  'lely-icon-caret-up': IconCaretUp,
  'lely-icon-cash': IconCash,
  'lely-icon-category': IconCategory,
  'lely-icon-central-unit': IconCentralUnit,
  'lely-icon-charging-station': IconChargingStation,
  'lely-icon-chart': IconChart,
  'lely-icon-chat-bubble': IconChatBubble,
  'lely-icon-check': IconCheck,
  'lely-icon-checklist': IconChecklist,
  'lely-icon-chevron-left': IconChevronLeft,
  'lely-icon-chevron-right': IconChevronRight,
  'lely-icon-circle-check': IconCircleCheck,
  'lely-icon-clock-interval': IconClockInterval,
  'lely-icon-clock': IconClock,
  'lely-icon-close': IconClose,
  'lely-icon-cloud-backup': IconCloudBackup,
  'lely-icon-cloud-done': IconCloudDone,
  'lely-icon-cloud-download': IconCloudDownload,
  'lely-icon-cloud': IconCloud,
  'lely-icon-comment': IconComment,
  'lely-icon-computer': IconComputer,
  'lely-icon-copy': IconCopy,
  'lely-icon-cow': IconCow,
  'lely-icon-creditcard': IconCreditcard,
  'lely-icon-data-points': IconDataPoints,
  'lely-icon-dashboard': IconDashboard,
  'lely-icon-digram': IconDigram,
  'lely-icon-disease': IconDisease,
  'lely-icon-dissatisfied': IconDissatisfied,
  'lely-icon-document': IconDocument,
  'lely-icon-download-done': IconDownloadDone,
  'lely-icon-download': IconDownload,
  'lely-icon-drag': IconDrag,
  'lely-icon-driver': IconDriver,
  'lely-icon-dry-off': IconDryOff,
  'lely-icon-east': IconEast,
  'lely-icon-edit': IconEdit,
  'lely-icon-emotions': IconEmotions,
  'lely-icon-engine': IconEngine,
  'lely-icon-error': IconError,
  'lely-icon-export': IconExport,
  'lely-icon-farm': IconFarm,
  'lely-icon-feed': IconFeed,
  'lely-icon-fence': IconFence,
  'lely-icon-fetch-cow': IconFetchCow,
  'lely-icon-filter': IconFilter,
  'lely-icon-fingerprint': IconFingerprint,
  'lely-icon-folder-open': IconFolderOpen,
  'lely-icon-folder-zip': IconFolderZip,
  'lely-icon-fullscreen-exit': IconFullscreenExit,
  'lely-icon-fullscreen': IconFullscreen,
  'lely-icon-grabber': IconGrabber,
  'lely-icon-graph-down': IconGraphDown,
  'lely-icon-graph-up': IconGraphUp,
  'lely-icon-graph-view': IconGraphView,
  'lely-icon-graph': IconGraph,
  'lely-icon-grass': IconGrass,
  'lely-icon-grid-view': IconGridView,
  'lely-icon-grid': IconGrid,
  'lely-icon-group-add': IconGroupAdd,
  'lely-icon-group-remove': IconGroupRemove,
  'lely-icon-group': IconGroup,
  'lely-icon-health-report': IconHealthReport,
  'lely-icon-health-treatment': IconHealthTreatment,
  'lely-icon-heat': IconHeat,
  'lely-icon-help': IconHelp,
  'lely-icon-herd-movement': IconHerdMovement,
  'lely-icon-herd': IconHerd,
  'lely-icon-hide': IconHide,
  'lely-icon-history': IconHistory,
  'lely-icon-home': IconHome,
  'lely-icon-hot-rins': IconHotRins,
  'lely-icon-human': IconHuman,
  'lely-icon-income': IconIncome,
  'lely-icon-information': IconInformation,
  'lely-icon-insemination': IconInsemination,
  'lely-icon-iris-group': IconIrisGroup,
  'lely-icon-iris': IconIris,
  'lely-icon-keyboard': IconKeyboard,
  'lely-icon-language': IconLanguage,
  'lely-icon-laptop': IconLaptop,
  'lely-icon-layers-clear': IconLayersClear,
  'lely-icon-layers': IconLayers,
  'lely-icon-link': IconLink,
  'lely-icon-list-bulletin': IconListBulletin,
  'lely-icon-list': IconList,
  'lely-icon-locate': IconLocate,
  'lely-icon-location': IconLocation,
  'lely-icon-lock': IconLock,
  'lely-icon-login': IconLogin,
  'lely-icon-logout': IconLogout,
  'lely-icon-m4use': IconM4use,
  'lely-icon-mail': IconMail,
  'lely-icon-manage': IconManage,
  'lely-icon-manure': IconManure,
  'lely-icon-map': IconMap,
  'lely-icon-medicine': IconMedicine,
  'lely-icon-menu': IconMenu,
  'lely-icon-mfr-dosing-roller': IconMfrDosingRoller,
  'lely-icon-mfr-feed-door-closing': IconMfrFeedDoorClosing,
  'lely-icon-mfr-feed-door-opening': IconMfrFeedDoorOpening,
  'lely-icon-mfr-open': IconMfrOpen,
  'lely-icon-mfr': IconMfr,
  'lely-icon-microphone': IconMicrophone,
  'lely-icon-milk-seperation': IconMilkSeperation,
  'lely-icon-milk': IconMilk,
  'lely-icon-minus': IconMinus,
  'lely-icon-mixer-motor': IconMixerMotor,
  'lely-icon-more-horizontal': IconMoreHorizontal,
  'lely-icon-more-vertical': IconMoreVertical,
  'lely-icon-mouse': IconMouse,
  'lely-icon-network-wifi': IconNetworkWifi,
  'lely-icon-neutral': IconNeutral,
  'lely-icon-north-east': IconNorthEast,
  'lely-icon-north-west': IconNorthWest,
  'lely-icon-north': IconNorth,
  'lely-icon-notification-mute': IconNotificationMute,
  'lely-icon-notification-on': IconNotificationOn,
  'lely-icon-notification': IconNotification,
  'lely-icon-notifications': IconNotifications,
  'lely-icon-objects': IconObjects,
  'lely-icon-ongoing': IconOngoing,
  'lely-icon-opportunities': IconOpportunities,
  'lely-icon-pains': IconPains,
  'lely-icon-pan': IconPan,
  'lely-icon-panel': IconPanel,
  'lely-icon-pause': IconPause,
  'lely-icon-pdb': IconPdb,
  'lely-icon-pending': IconPending,
  'lely-icon-person-approve': IconPersonApprove,
  'lely-icon-person-assign': IconPersonAssign,
  'lely-icon-person-remove': IconPersonRemove,
  'lely-icon-person-unassign': IconPersonUnassign,
  'lely-icon-person': IconPerson,
  'lely-icon-phone': IconPhone,
  'lely-icon-picture': IconPicture,
  'lely-icon-pinc-zoom-out': IconPincZoomOut,
  'lely-icon-pinch-zoom-in': IconPinchZoomIn,
  'lely-icon-play': IconPlay,
  'lely-icon-plus': IconPlus,
  'lely-icon-power-management': IconPowerManagement,
  'lely-icon-power': IconPower,
  'lely-icon-pregnancy': IconPregnancy,
  'lely-icon-printer': IconPrinter,
  'lely-icon-qr-code-scanner': IconQrCodeScanner,
  'lely-icon-qr-code': IconQrCode,
  'lely-icon-redo': IconRedo,
  'lely-icon-refresh': IconRefresh,
  'lely-icon-reminder': IconReminder,
  'lely-icon-reports': IconReports,
  'lely-icon-reproduction-check': IconReproductionCheck,
  'lely-icon-reset': IconReset,
  'lely-icon-robot': IconRobot,
  'lely-icon-rotate-left': IconRotateLeft,
  'lely-icon-rotate-map': IconRotateMap,
  'lely-icon-rotate-right': IconRotateRight,
  'lely-icon-route-layers': IconRouteLayers,
  'lely-icon-routes': IconRoutes,
  'lely-icon-routing': IconRouting,
  'lely-icon-satisfied': IconSatisfied,
  'lely-icon-scanner': IconScanner,
  'lely-icon-scatterplot': IconScatterplot,
  'lely-icon-screen-rotation': IconScreenRotation,
  'lely-icon-search': IconSearch,
  'lely-icon-send': IconSend,
  'lely-icon-sensor': IconSensor,
  'lely-icon-settings': IconSettings,
  'lely-icon-shopping-cart': IconShoppingCart,
  'lely-icon-show': IconShow,
  'lely-icon-signal-cellular-0-bar': IconSignalCellular0Bar,
  'lely-icon-signal-cellular-2-bar': IconSignalCellular2Bar,
  'lely-icon-signal-cellular-4-bar': IconSignalCellular4Bar,
  'lely-icon-signal-cellular-off': IconSignalCellularOff,
  'lely-icon-signal-wifi-bad': IconSignalWifiBad,
  'lely-icon-signal-wifi-off': IconSignalWifiOff,
  'lely-icon-sires': IconSires,
  'lely-icon-sort-disabled': IconSortDisabled,
  'lely-icon-sort-down': IconSortDown,
  'lely-icon-sort-up': IconSortUp,
  'lely-icon-sort': IconSort,
  'lely-icon-south-east': IconSouthEast,
  'lely-icon-south-west': IconSouthWest,
  'lely-icon-south': IconSouth,
  'lely-icon-stacked-bar-chart': IconStackedBarChart,
  'lely-icon-star-empty': IconStarEmpty,
  'lely-icon-star-filled': IconStarFilled,
  'lely-icon-stop': IconStop,
  'lely-icon-swipe-down': IconSwipeDown,
  'lely-icon-swipe-left-and-right': IconSwipeLeftAndRight,
  'lely-icon-swipe-left': IconSwipeLeft,
  'lely-icon-swipe-up': IconSwipeUp,
  'lely-icon-table-row': IconTableRow,
  'lely-icon-table': IconTable,
  'lely-icon-tablet': IconTablet,
  'lely-icon-tasks': IconTasks,
  'lely-icon-thermostat': IconThermostat,
  'lely-icon-thumb-down': IconThumbDown,
  'lely-icon-thumb-up': IconThumbUp,
  'lely-icon-touch': IconTouch,
  'lely-icon-traffic': IconTraffic,
  'lely-icon-transfer': IconTransfer,
  'lely-icon-trash': IconTrash,
  'lely-icon-tv': IconTv,
  'lely-icon-undo': IconUndo,
  'lely-icon-update': IconUpdate,
  'lely-icon-upload': IconUpload,
  'lely-icon-valve': IconValve,
  'lely-icon-version': IconVersion,
  'lely-icon-very-dissatisfied': IconVeryDissatisfied,
  'lely-icon-very-satisfied': IconVerySatisfied,
  'lely-icon-view-list': IconViewList,
  'lely-icon-warning': IconWarning,
  'lely-icon-watch': IconWatch,
  'lely-icon-water': IconWater,
  'lely-icon-west': IconWest,
  'lely-icon-wifi-off': IconWifiOff,
  'lely-icon-wifi': IconWifi,
  'lely-icon-young-stock': IconYoungStock,
  'lely-icon-zoom-in': IconZoomIn,
  'lely-icon-zoom-out': IconZoomOut,
};

export default iconComponents;
