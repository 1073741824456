import { put, take, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  setCompositeStateError,
  setMilkJarWeightData,
  updateMilkJarWeight,
} from '../../../reducers/compositeStatesSlice';
import { ChartData } from '../../../reducers/types/vectorDataTypes';

export default function* milkJarSaga(api) {
  while (true) {
    const updateMilkJarWeightRequestedAction: ReturnType<
      typeof updateMilkJarWeight
    > = yield take(updateMilkJarWeight.type);
    const { entityId, startDate, endDate } =
      updateMilkJarWeightRequestedAction.payload;

    yield call(getMilkJarWeightData, api, entityId, startDate, endDate);
  }
}

/** Create request to get chart data for milk jar weight
 * @param {string} entityId - entity id
 * @param {Date} startDate - Start date of the data range
 * @param {Date} endDate - Start date of the data range
 */
function* getMilkJarWeightData(api, entityId, startDate, endDate) {
  try {
    const { data: chartData }: AxiosResponse<ChartData[]> = yield call(
      api.getMilkJarWeightDetails,
      entityId,
      startDate,
      endDate,
    );
    yield put(setMilkJarWeightData({ chartData, startDate, endDate }));
  } catch (error) {
    yield put(setCompositeStateError((error as any).message as string));
  }
}
