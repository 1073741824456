export const getAstronautMenuOptions = (
  isEngineer: boolean,
  isDiagnosticPageActive: boolean,
  isUpdatesPageActive: boolean,
): any[] => {
  return [
    {
      menuTitle: 'Overview',
      iconClass: 'lely-icon-activities',
      appendixUrl: 'overview',
    },

    {
      menuTitle: 'Diagnostics',
      iconClass: 'lely-icon-reports',
      appendixUrl: 'domains/canbus',
      featureFlag: isDiagnosticPageActive,
    },
    {
      menuTitle: 'Data Explorer',
      iconClass: 'lely-icon-graph-view',
      appendixUrl: 'Data_Explorer',
      overviewParam: true,
    },
    {
      menuTitle: 'Events',
      iconClass: 'lely-icon-tasks',
      appendixUrl: 'events',
    },
    {
      menuTitle: 'Logs',
      iconClass: 'lely-icon-table',
      appendixUrl: 'logs',
      featureFlag: isEngineer,
    },
    {
      menuTitle: 'Settings',
      iconClass: 'lely-icon-settings',
      appendixUrl: 'settings',
    },
    {
      menuTitle: 'Updates',
      iconClass: 'lely-icon-update',
      appendixUrl: 'updates',
      featureFlag: isUpdatesPageActive,
    },
  ];
};

export const getVectorMenuOptions = (
  isActiveVectorEventsPage: boolean,
): any[] => {
  return [
    {
      menuTitle: 'Vector Data Explorer',
      iconClass: 'lely-icon-graph-view',
      appendixUrl: 'Vector_Data_Explorer',
    },
    {
      menuTitle: 'Vector Performance Indicators',
      iconClass: 'lely-icon-graph',
      appendixUrl: 'Performance_Indicators',
    },
    {
      menuTitle: 'Events',
      iconClass: 'lely-icon-tasks',
      appendixUrl: 'vde-events',
      featureFlag: isActiveVectorEventsPage,
    },
  ];
};

export const isPageActive = (urlPageName, appendixUrl) => {
  if (urlPageName === appendixUrl) {
    return true;
  }
  if (urlPageName.includes(appendixUrl)) {
    return true;
  }
  if (appendixUrl.includes(urlPageName)) {
    return true;
  }
  return false;
};
