import React from 'react';
import iconComponents from '../../../resources/icons/lelyIcons';
import './Icon.scss';

type IconComponents = {
  [key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

// Ensure iconComponents matches this type
const typedIconComponents: IconComponents = iconComponents;

// Props for the Icon component
type IconProps = Omit<React.SVGProps<SVGSVGElement>, 'name'> & {
  name: keyof typeof typedIconComponents;
};

function Icon({
  name,
  className = '',

  ...props
}: IconProps): JSX.Element | null {
  const IconComponent = typedIconComponents[name];

  if (!IconComponent) {
    // console.warn(`Icon "${name}" not found`);
    return null;
  }

  return (
    <IconComponent
      className={`icon-svg ${name} ${className}`.trim()}
      {...props}
    />
  );
}
export default Icon;
