import cloneDeep from 'lodash/cloneDeep';
import { layoutConstants } from '../../../common/constants';
import { Robots } from '../../../common/types';
import { getLastLineChartMouseX, getLastLineChartMouseY } from '../util';
import {
  OverallStateStage,
  SuccessfulMilkingStage,
  FailedMilkingStage,
  TakeOffReasonObject,
} from '../../../reducers/types/compositeStateTypes';

const { DEFAULT_TOOLTIP_WIDTH_IN_PX } = layoutConstants[Robots.Astronaut];

export const onEventMouseOverHandler = (
  d,
  i,
  visibleZoomLevelWidthInPx,
  leftVerticalLineOffsetInPixels,
) => {
  const hoveredEvent = cloneDeep(i);
  const hoveredItemXCoordinate = d.clientX - leftVerticalLineOffsetInPixels;
  const rightmostPossibleXCoordinateForTooltip =
    visibleZoomLevelWidthInPx - DEFAULT_TOOLTIP_WIDTH_IN_PX;
  hoveredEvent.tooltipXCoordinate = Math.min(
    hoveredItemXCoordinate,
    rightmostPossibleXCoordinateForTooltip,
  );
  hoveredEvent.tooltipYCoordinate = 0;
  return hoveredEvent;
};

export const onHoverLineChartPointHandler = (
  d,
  visibleZoomLevelWidthInPx,
  leftVerticalLineOffsetInPixels,
) => {
  const hoveredPoint = cloneDeep(d);
  const hoveredItemXCoordinate =
    getLastLineChartMouseX() - leftVerticalLineOffsetInPixels;
  const rightmostPossibleXCoordinateForTooltip =
    visibleZoomLevelWidthInPx - DEFAULT_TOOLTIP_WIDTH_IN_PX;
  hoveredPoint.tooltipXCoordinate = Math.min(
    hoveredItemXCoordinate,
    rightmostPossibleXCoordinateForTooltip,
  );
  hoveredPoint.tooltipYCoordinate = getLastLineChartMouseY();
  return hoveredPoint;
};

export const isMilkingStateSpanSuccesfulOrFailed = (
  stage: OverallStateStage | SuccessfulMilkingStage | FailedMilkingStage,
): stage is SuccessfulMilkingStage | FailedMilkingStage => {
  return stage.name === 'Failed milking' || stage.name === 'Successful milking';
};

export const isTakeOffReasonObject = (
  takeOffReason: string | TakeOffReasonObject,
): takeOffReason is TakeOffReasonObject => {
  return takeOffReason && typeof takeOffReason !== 'string'
    ? 'teat' in takeOffReason
    : false;
};
