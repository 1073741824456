import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getFilteredGraphConstituents } from '../components/AstronautDataExplorer/util/CustomZoomDomainFiltering';
import {
  MAX_CONNECTION_ATTEMPTS,
  MIN_CONNECTION_ATTEMPTS,
} from '../common/constants';
import {
  CompositeStateData,
  TakeOffReasonObject,
} from './types/compositeStateTypes';
import {
  isMilkingStateSpanSuccesfulOrFailed,
  isTakeOffReasonObject,
} from '../components/AstronautDataExplorer/util/compositeStateChartUtils';
import { ChartData } from './types/vectorDataTypes';

export type CompositeStatesInitialStateType = {
  compositeStatesData: CompositeStateData;
  milkJarWeightData: ChartData[];
  milkJarWeightTakeOffReasonData: TakeOffReasonObject[];
  chartColorDictionary: any;
  isCompositeStatesDataError: boolean;
  errorMessage: string;
};

export const initialState: CompositeStatesInitialStateType = {
  compositeStatesData: {} as CompositeStateData,
  milkJarWeightData: {} as ChartData[],
  milkJarWeightTakeOffReasonData: [],
  chartColorDictionary: {},
  isCompositeStatesDataError: false,
  errorMessage: '',
};

export const compositeStatesSlice = createSlice({
  name: 'compositeStatesSlice',
  initialState,
  reducers: {
    setMilkJarWeightData: (
      state,
      action: PayloadAction<{
        chartData: ChartData[];
        startDate: Date;
        endDate: Date;
      }>,
    ) => {
      const { chartData, startDate, endDate } = action.payload;
      const graphConstituents = getFilteredGraphConstituents(
        chartData[0].graphs[0].axesData,
        [new Date(startDate), new Date(endDate)],
        true,
      );

      chartData[0].graphDates = graphConstituents.graphDates;
      chartData[0].graphValues = graphConstituents.graphValues;

      state.milkJarWeightData = chartData;
    },
    setCompositeStateData: (
      state,
      action: PayloadAction<{
        compositeStatesData: CompositeStateData;
      }>,
    ) => {
      const { compositeStatesData } = action.payload;
      const devicesArray = [
        'teatLeftRear',
        'teatRightRear',
        'teatRightFront',
        'teatLeftFront',
      ];
      Object.keys(compositeStatesData).forEach((currentStateCategory) => {
        const stateCategorySubtypes = compositeStatesData[currentStateCategory];
        stateCategorySubtypes.forEach((currentStateSubtype) => {
          if (currentStateSubtype?.spans?.length > 0) {
            if (currentStateSubtype.name === 'Failed milking') {
              currentStateSubtype?.spans.forEach((span) => {
                state.chartColorDictionary[
                  `${currentStateSubtype.name}_${span.details.result}`
                ] = span.color;
              });
            } else {
              state.chartColorDictionary[currentStateSubtype.name] =
                currentStateSubtype.color;
            }
          }
          currentStateSubtype.spans.forEach((currentStateEvent) => {
            currentStateEvent.startTime = new Date(currentStateEvent.startTime);
            currentStateEvent.endTime = new Date(currentStateEvent.endTime);
            currentStateEvent.name = currentStateSubtype.name;
            currentStateEvent.color = currentStateEvent.color
              ? currentStateEvent.color
              : currentStateSubtype.color;
          });
        });
      });
      compositeStatesData.milkingStages
        .filter(
          (currentMilkingStage) => currentMilkingStage.name === 'Attaching',
        )
        .forEach((currentStage) => {
          // current stage is infered correctly but the terminal is thinking otherwise idk why
          (currentStage as any).maxConnectionAttempts = MAX_CONNECTION_ATTEMPTS;
          (currentStage as any).minConnectionAttempts = MIN_CONNECTION_ATTEMPTS;
        });

      compositeStatesData?.overallState?.forEach((milkState) => {
        if (isMilkingStateSpanSuccesfulOrFailed(milkState)) {
          milkState.spans.forEach((currentMilkStateSpan) => {
            if (!_.isEmpty(currentMilkStateSpan.details)) {
              Object.keys(currentMilkStateSpan.details).forEach(
                (activityKey) => {
                  const milkingStateObject: string | TakeOffReasonObject =
                    currentMilkStateSpan.details[activityKey].takeOffReason;
                  if (
                    devicesArray.includes(activityKey) &&
                    isTakeOffReasonObject(milkingStateObject)
                  ) {
                    // Generally the time is a string, and here it is assigned with Date?
                    milkingStateObject.time = new Date(
                      milkingStateObject.time,
                    ) as unknown as any;

                    state.milkJarWeightTakeOffReasonData.push(
                      milkingStateObject,
                    );
                  }
                },
              );
            }
          });
        }
      });

      state.compositeStatesData = compositeStatesData;

      state.isCompositeStatesDataError = false;
      state.errorMessage = '';
    },
    setCompositeStateError: (state, action: PayloadAction<string>) => {
      state.isCompositeStatesDataError = true;
      state.errorMessage = action.payload;
    },
    resetCompositeStatesData: () => initialState,
  },
});

export const {
  setMilkJarWeightData,
  setCompositeStateData,
  setCompositeStateError,
  resetCompositeStatesData,
} = compositeStatesSlice.actions;

export const updateMilkJarWeight = createAction<{
  entityId: string;
  startDate: string;
  endDate: string;
}>('compositeStatesSlice/updateMilkJarWeight');

export const requestCompositeStateUpdate = createAction<{
  entityId: string;
  startDate: string;
  endDate: string;
}>('compositeStatesSlice/requestCompositeStateUpdate');

export default compositeStatesSlice.reducer;
