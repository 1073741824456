import { put, take, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_VECTOR_CHARTS_CONFIGURATION_REQUEST } from '../types/vectorDataChartTypes';
import { setVectorConfigurationSuccess } from '../../../reducers/vectorConfigurationSlice';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { VectorConfigurationApi } from '../../../api/lelyBackend/vectorConfigurationApi';

let currentLoadId = 0;

export default function* vectorConfigurationSaga(api: VectorConfigurationApi) {
  while (true) {
    yield take(GET_VECTOR_CHARTS_CONFIGURATION_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getVectorConfiguration, api);
  }
}

function* getVectorConfiguration(api: VectorConfigurationApi) {
  try {
    // TODO: go trough vector configuration request data and do correct typings
    const vectorConfigurationData: AxiosResponse<any> = yield call(
      api.getVectorConfiguration,
    );
    yield put(setVectorConfigurationSuccess(vectorConfigurationData.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setEndLoading(currentLoadId));
  }
}
