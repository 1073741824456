import axiosInstance from '../axiosInstance';
import { GET_VECTOR_CONFIGURATION_SERVICE_URL } from './config';

export const api = {
  async getVectorConfiguration() {
    return axiosInstance.get(GET_VECTOR_CONFIGURATION_SERVICE_URL);
  },
};

export type VectorConfigurationApi = typeof api;
