import { all, takeLatest } from 'redux-saga/effects';
import { fetchMoreEvents, getVdeEvents } from '../reducers/eventDetailsSlice';
import loginSaga from '../components/LoginForm/saga';
import logoutSaga from '../components/LogoutButton/saga';
import farmDetailsSaga from '../components/FarmDetails/saga';
import {
  eventPageDetailsSaga,
  getMoreEvents,
} from '../containers/EventsPage/sagas/saga';
import selectedEventPageDetailsSaga from '../containers/EventsPage/sagas/selectedEventSaga';
import domainsSaga from '../components/DomainDetails/saga';
import entityInformationSaga from '../common/components/FarmAndEntityDetailsHeader/saga';
import domainComponentsSaga from '../components/DomainElements/saga';
import updateNameDetailsSaga from '../components/EditFields/saga';
import errorCodeSaga from '../common/redux/errorCodeHandler/saga';
import alarmDropdownLevelsSaga from '../common/redux/alarmDropdownLevel/saga';
import eventDropdownLevelsSaga from '../common/redux/eventDropdownLevel/saga';
import eventDropdownTypesSaga from '../common/redux/eventDropdownType/saga';
import canbusTroubleshootSaga from '../common/redux/canbusTroubleshooter/saga';
import googleAnalyticTrackingSaga from '../common/redux/googleAnalyticTracking/saga';
import canbusTroubleshootTutorialSaga from '../common/redux/canbusTroubleshooterTutorial/saga';
import entityKpiDetailsSaga from '../common/components/KpiGrid/saga';
import alarmTablesSaga from '../common/components/TableGrid/saga';
import featureFlagSaga from '../common/redux/FeatureFlag/saga';
import entityStatusSaga from '../components/EntityState/saga';
import entityBackupSettingsSaga from '../containers/BackupSettings/saga';
import createEntityBackupSettingsSaga from '../containers/BackupSettings/createSaga';
import updateEntityBackupSettingsSaga from '../containers/BackupSettings/updateSaga';
import restoreEntityBackupSettingsSaga from '../containers/BackupSettings/restoreSaga';
import entitySettingsSaga from '../containers/Settings/sagas/settingsSaga';
import entitySettingsOptionsSaga from '../containers/Settings/sagas/settingsOptionsSaga';
import applicationCacheSaga from '../common/components/CacheBust/saga';
import dataChartSaga from '../components/DataCharts/sagas/dataChartSaga';
import dataChartEventsSaga from '../components/DataCharts/sagas/dataChartEventsSaga';
import dataChartConfigurationSaga from '../components/DataCharts/sagas/dataConfigChartSaga';
import vectorDataChartSaga from '../components/Vector/sagas/vectorDataChartSaga';
import vectorConfigurationSaga from '../components/Vector/sagas/vectorConfigurationSaga';
import updateLanguageSaga from '../common/components/Translation/saga';
import compositeStateSaga from '../components/DataCharts/sagas/compositeStateSaga';
import milkJarSaga from '../components/DataCharts/sagas/milkJarSaga';
import eventsLevelMenuOptionsSaga from '../common/redux/eventDropdownLevelMenuOptions/saga';
import farmsSaga from '../components/FarmsTable/saga';
import updatesSaga from '../components/UpdatesDetails/updatesSaga';
import updateHistorySaga from '../components/UpdatesHistory/sagas/updateHistorySaga';
import updateHistorySagaForSystem from '../components/UpdatesHistory/sagas/updateHistorySagaForSystem';
import kpiSaga from '../components/PerformanceIndicators/kpiSaga';
import getUserRolesSaga from '../common/components/UserRoles/getUserRolesSaga';

// Api's
import { api as updateEntityNameApi } from '../api/lelyBackend/updateEntityNameApi';
import { api as entityInfoApi } from '../api/lelyBackend/entityInfoApi';
import { api as loginApi } from '../api/lelyBackend/loginApi';
import { api as logoutApi } from '../api/lelyBackend/logoutApi';
import { api as updatesApi } from '../api/lelyBackend/updatesApi';

import { api as farmDetailsApi } from '../api/lelyBackend/farmDetailsApi';
import { api as alarmsApi } from '../api/lelyBackend/alarmsApi';
import { api as eventsApi } from '../api/lelyBackend/eventsApi';
import { api as eventsPageApi } from '../api/lelyBackend/eventsPageApi';
import { api as domainsApi } from '../api/lelyBackend/domainsApi';
import { api as logsApi } from '../api/lelyBackend/logsApi';
import { api as settingsApi } from '../api/lelyBackend/settingsApi';
import { api as troubleshootApi } from '../api/lelyBackend/troubleshootApi';
import { api as entityStatusApi } from '../api/lelyBackend/entityStatusApi';
import { api as entityKpiDetailsApi } from '../api/lelyBackend/entityKpiDetailsApi';
import { api as backupsApi } from '../api/lelyBackend/backupsApi';
import { api as dataChartsApi } from '../api/lelyBackend/dataChartsApi';
import { api as compositeStatesApi } from '../api/lelyBackend/compositeStateApi';
import { api as vectorConfigurationApi } from '../api/lelyBackend/vectorConfigurationApi';
import { api as updateLelyPreferredLanguage } from '../api/lelyBackend/translationApi';
import { api as getUserRolesApi } from '../api/lelyBackend/userRoles';
import { api as updateHistoryApi } from '../api/lelyBackend/updateHistoryApi';
import { api as kpiApi } from '../api/lelyBackend/kpiDataApi';
// Mock api's
import { api as mockLoginApi } from '../api/mockApi/loginApi';
import { api as mockLogoutApi } from '../api/mockApi/logoutApi';
import { api as mockFarmDetailsApi } from '../api/mockApi/farmDetailsApi';
import { api as mockUpdateHistoryApi } from '../api/mockApi/updateHistoryApi';
import { setNetworkError } from '../reducers/networkErrorSlice';
import getBackupProgressSaga from '../containers/BackupSettings/listenForBackupCreatonSaga';
import { getFarms } from '../reducers/farmsSlice';
import { checkFeatureFlag } from '../reducers/featureFlagSlice';
import { getLanguages } from '../common/components/Translation/actions';
import getLanguagesRequest from '../common/components/Translation/getLanguagesSaga';
import logsFilterMenuSaga from '../components/LogsFilterMenu/logsFilterMenuSaga';
import logsContentSaga from '../components/LogsContentArea/logsContentSaga';
import logsDownloadSaga from '../components/LogsFilterMenu/logsDownloadSaga';
import { getVdeEventsSaga } from '../containers/EventsPageVDE/sagas/saga';

/** Root saga.
 * @return {Object} - return store
 */
function* rootSaga() {
  const envVariablesPassed = process.env;
  const doUseMocks = envVariablesPassed.REACT_APP_USE_MOCKS === 'true';
  if (doUseMocks) {
    yield all([
      loginSaga(mockLoginApi),
      logoutSaga(mockLogoutApi),
      farmDetailsSaga(mockFarmDetailsApi),
      updateHistorySaga(mockUpdateHistoryApi),
    ]);
  } else {
    yield all([
      loginSaga(loginApi),
      logoutSaga(logoutApi),
      takeLatest(getFarms.type, farmsSaga),
      updatesSaga(updatesApi),
      farmDetailsSaga(farmDetailsApi),
      alarmTablesSaga(alarmsApi),
      eventPageDetailsSaga(),
      takeLatest(fetchMoreEvents.type, getMoreEvents),
      takeLatest(getVdeEvents.type, getVdeEventsSaga),
      selectedEventPageDetailsSaga(eventsPageApi),
      domainsSaga(domainsApi),
      domainComponentsSaga(domainsApi),
      logsFilterMenuSaga(logsApi),
      logsContentSaga(logsApi),
      logsDownloadSaga(logsApi),
      entityInformationSaga(entityInfoApi),
      entitySettingsSaga(settingsApi),
      entitySettingsOptionsSaga(settingsApi),
      takeLatest(setNetworkError.type, errorCodeSaga),
      updateNameDetailsSaga(updateEntityNameApi),
      alarmDropdownLevelsSaga(alarmsApi),
      eventDropdownLevelsSaga(eventsApi),
      eventDropdownTypesSaga(eventsApi),
      canbusTroubleshootSaga(troubleshootApi),
      canbusTroubleshootTutorialSaga(troubleshootApi),
      entityStatusSaga(entityStatusApi),
      googleAnalyticTrackingSaga(),
      entityKpiDetailsSaga(entityKpiDetailsApi),
      entityBackupSettingsSaga(backupsApi),
      createEntityBackupSettingsSaga(backupsApi),
      getBackupProgressSaga(backupsApi),
      updateEntityBackupSettingsSaga(backupsApi),
      restoreEntityBackupSettingsSaga(backupsApi),
      dataChartSaga(dataChartsApi),
      dataChartEventsSaga(dataChartsApi),
      dataChartConfigurationSaga(dataChartsApi),
      vectorDataChartSaga(dataChartsApi),
      vectorConfigurationSaga(vectorConfigurationApi),
      applicationCacheSaga(),
      takeLatest(checkFeatureFlag.type, featureFlagSaga),
      updateLanguageSaga(updateLelyPreferredLanguage),
      takeLatest(getLanguages.type, getLanguagesRequest),
      getUserRolesSaga(getUserRolesApi),
      eventsLevelMenuOptionsSaga(dataChartsApi),
      compositeStateSaga(compositeStatesApi),
      updateHistorySaga(updateHistoryApi),
      updateHistorySagaForSystem(updateHistoryApi),
      milkJarSaga(dataChartsApi),
      kpiSaga(kpiApi),
    ]);
  }
}

export default rootSaga;
