import { put, take, call } from 'redux-saga/effects';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { CompositeStateGraphApiType } from '../../../api/lelyBackend/compositeStateApi';
import {
  requestCompositeStateUpdate,
  resetCompositeStatesData,
  setCompositeStateData,
  setCompositeStateError,
} from '../../../reducers/compositeStatesSlice';

let currentLoadId = 0;

export default function* compositeStateSaga(api: CompositeStateGraphApiType) {
  while (true) {
    const updateCompositeStateRequestedAction: ReturnType<
      typeof requestCompositeStateUpdate
    > = yield take(requestCompositeStateUpdate.type);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield put(resetCompositeStatesData());
    const { entityId, startDate, endDate } =
      updateCompositeStateRequestedAction.payload;
    yield call(getCompositeStateData, api, entityId, startDate, endDate);
  }
}

function* getCompositeStateData(
  api: CompositeStateGraphApiType,
  entityId: string,
  startDate: string,
  endDate: string,
) {
  try {
    const milkingStateData = yield call(
      api.getCompositeStateDetails,
      'milking',
      entityId,
      startDate,
      endDate,
    );

    const cleaningStateData = yield call(
      api.getCompositeStateDetails,
      'cleaning',
      entityId,
      startDate,
      endDate,
    );

    yield put(
      setCompositeStateData({
        compositeStatesData: {
          ...milkingStateData.data,
          ...cleaningStateData.data,
        },
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    const errorMessage = (error as any).message;
    yield put(setCompositeStateError(errorMessage));
    yield put(setEndLoading(currentLoadId));
  }
}
