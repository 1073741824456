import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Farm } from './types';
import { setFarms, setIsFarmError } from '../../reducers/farmsSlice';
import { api } from '../../api/lelyBackend/farmsApi';

export default function* farmsSaga() {
  try {
    const response: AxiosResponse<Farm[]> = yield call(api.getFarms);
    yield put(setFarms(response.data));
  } catch (error: any) {
    yield put(setIsFarmError(error));
  }
}
