export const UPDATE_DATA_CHART_REQUESTED = 'UPDATE_DATA_CHART_REQUESTED';
export const MILK_JAR_WEIGHT_REQUEST_ERROR = 'MILK_JAR_WEIGHT_REQUEST_ERROR';
export const DATA_KEY_CHARTS_DATA_RECEIVED = 'DATA_KEY_CHARTS_DATA_RECEIVED';
export const DATA_KEY_CHARTS_DATA_ERROR = 'DATA_KEY_CHARTS_DATA_ERROR';
export const BRUSH_ON_BOTTOM_CHART_DETECTED = 'BRUSH_ON_BOTTOM_CHART_DETECTED';
export const WHEEL_ZOOM_ONGOING_STATUS_CHANGE =
  'WHEEL_ZOOM_ONGOING_STATUS_CHANGE';
export const RESET_DOMAIN = 'RESET_DOMAIN';
export const RESET_CHARTS_DATA = 'RESET_CHARTS_DATA';
export const GET_CHARTS_CONFIGURATION_REQUEST =
  'GET_CHARTS_CONFIGURATION_REQUEST';
export const GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS =
  'GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS';
export const GET_CHARTS_CONFIGURATION_REQUEST_ERROR =
  'GET_CHARTS_CONFIGURATION_REQUEST_ERROR';
export const INIT_ZOOM_CHART = 'INIT_ZOOM_CHART';
export const GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS =
  'GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS';
export const GET_CHART_EVENTS_REQUEST = 'GET_CHART_EVENTS_REQUEST';
export const GET_CHART_EVENTS_REQUEST_SUCCESS =
  'GET_CHART_EVENTS_REQUEST_SUCCESS';
export const GET_CHART_EVENTS_REQUEST_ERROR = 'GET_CHART_EVENTS_REQUEST_ERROR';
