/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { format, addHours, subHours } from 'date-fns';
import { updateRcbRealTimeColoring } from './canbusService';
import {
  DATE_T_FORMAT_BE,
  DATE_T_FORMAT_BE_MILLISECONDS,
  MOMENTJS_UI_DATE_FORMAT,
  UI_DATE_FORMAT,
} from './constants';

export const CANBUS_DOMAIN_NAME = 'canbus';

export const redirectToDomainPage = (navigate, entityId, page, time) => {
  if (entityId) {
    navigate(`/entities/${entityId}/domains/${page}`, { state: { time } });
  }
};

export const redirectToAlarmPage = (
  entityId,
  data,
  farmName,
  navigate,
  context,
) => {
  if (entityId) {
    let time;
    if (data?.x) {
      time = data?.x.toISOString();
    } else {
      time = data.date.start;
    }

    const encodedFarmName = encodeURIComponent(farmName ?? '');

    const url = context
      ? `/entities/${entityId}/events?farmName=${encodedFarmName}&start=${time}`
      : `/entities/${entityId}/events?farmName=${encodedFarmName}`;
    navigate(url, {
      state: { data, context },
    });
  }
};

export const redirectToDataExplorerPage = (navigate, entityId, time, range) => {
  if (entityId) {
    navigate(`/entities/${entityId}/Data_Explorer`, { state: { time, range } });
  }
};

export const redirectToVDEDataExplorerPage = (
  navigate,
  entityId,
  startDate,
  endDate,
) => {
  if (entityId) {
    navigate(`/entities/${entityId}/Vector_Data_Explorer`, {
      state: { startDate, endDate },
    });
  }
};

export const redirectToVectorAlarmPage = (
  entityId,
  data,
  farmName,
  navigate,
  context,
) => {
  if (entityId) {
    const encodedFarmName = encodeURIComponent(farmName ?? '');
    const startDate = format(subHours(data.time, 12), DATE_T_FORMAT_BE);

    let endDateValue = addHours(data.time, 12);
    const currentDateTime = new Date();

    if (endDateValue > currentDateTime) {
      endDateValue = currentDateTime;
    }
    const endDate = format(endDateValue, DATE_T_FORMAT_BE);

    // Construct URL
    const url = context
      ? `/entities/${entityId}/vde-events?farmName=${encodedFarmName}&start=${startDate}&end=${endDate}&offset=0`
      : `/entities/${entityId}/vde-events?farmName=${encodedFarmName}`;

    navigate(url, {
      state: { data, context },
    });
  }
};

export const redirectToLogsPage = (navigate, entityId, time) => {
  if (entityId) {
    navigate(`/entities/${entityId}/logs?date=${time}`);
  }
};

export const getDomainIdByDomainName = (domainName, domainsData) => {
  const filteredDomains = domainsData.filter((domain) => {
    return domain.domainName.toLowerCase() === domainName.toLowerCase();
  });
  return filteredDomains && filteredDomains.length && filteredDomains[0].id;
};

export const getTroubleshootDataById = (arrayOfData, troubleshootId) => {
  const filterTroubleshootData = arrayOfData.filter(
    (item) => item.id === troubleshootId,
  );
  return filterTroubleshootData;
};

export const getFarmTimeZoneFormatForVDE = (startDate, entityInfo) => {
  const startDateToLocale = startDate.toLocaleString('en-US', {
    timeZone: entityInfo.farmTimeZone,
  });
  const formatedDate = new Date(
    new Date(startDateToLocale).setHours(24, 0, 0, 0),
  );
  return formatedDate;
};

export const getFarmTimeZoneFormat = (startDate, entityInfo) => {
  return new Date(
    startDate.toLocaleString('en-US', {
      timeZone: entityInfo.farmTimeZone,
    }),
  );
};

export const getDateInTFormat = (javascriptDateObject) => {
  if (typeof javascriptDateObject === 'string') {
    if (javascriptDateObject.includes('T')) {
      return javascriptDateObject;
    }
    const dateToObj = moment(
      javascriptDateObject,
      MOMENTJS_UI_DATE_FORMAT,
    ).toDate();
    return format(dateToObj, DATE_T_FORMAT_BE);
  }
  return format(javascriptDateObject, DATE_T_FORMAT_BE);
};

export const getDateInTFormatWithMs = (dateObject) => {
  return format(dateObject, DATE_T_FORMAT_BE_MILLISECONDS);
};

export const calculateAndReturnDateInTFormat = (
  isSubtractOperation,
  javascriptDateObject,
  hoursToSubtractOrAdd,
) => {
  const timeToBeAdded =
    hoursToSubtractOrAdd < 1 ? hoursToSubtractOrAdd * 60 : hoursToSubtractOrAdd;
  const timeMetric = hoursToSubtractOrAdd < 1 ? 'minutes' : 'hours';
  const momentOperation = isSubtractOperation ? 'subtract' : 'add';
  const dateCalc = moment(javascriptDateObject, MOMENTJS_UI_DATE_FORMAT)
    [momentOperation](timeToBeAdded, timeMetric)
    .toDate();
  if (new Date(dateCalc).getTime()) {
    return format(dateCalc, DATE_T_FORMAT_BE);
  }
  return format(new Date(), DATE_T_FORMAT_BE);
};

export const subtractAndReturnDateInTFormat = (
  javascriptDateObject,
  subtractedHours,
) => {
  return calculateAndReturnDateInTFormat(
    true,
    javascriptDateObject,
    subtractedHours,
  );
};

export const addAndReturnDateInTFormat = (javascriptDateObject, addedHours) => {
  return calculateAndReturnDateInTFormat(
    false,
    javascriptDateObject,
    addedHours,
  );
};

export const getDateInCSharpFormat = (timestamp) => {
  const dateToObj = moment(timestamp, MOMENTJS_UI_DATE_FORMAT).toDate();
  return format(dateToObj, DATE_T_FORMAT_BE);
};

export const getTimeKey = (timestamp) => {
  return getDateInCSharpFormat(timestamp).substr(0, 13);
};

export const getDateMinusMinutes = (dateObject, minutesCount) => {
  return moment(dateObject).subtract({ minutes: minutesCount }).toDate();
};

export const getDatePlusMinutes = (dateObject, minutesCount) => {
  return moment(dateObject).add({ minutes: minutesCount }).toDate();
};

export const getDateMinus1Hour = (timestamp) => {
  const subDate = subHours(timestamp, 1);
  return format(subDate, UI_DATE_FORMAT);
};

export const getDatePlus1Hour = (timestamp) => {
  const addDate = addHours(timestamp, 1);
  return format(addDate, UI_DATE_FORMAT);
};

export const getModifiedMetadataWithDataFromSignalR = (
  frontendMetadata,
  signalRLiveData,
) => {
  const mixedMetadataWithDataFromBothFrontendAndSignalR =
    signalRLiveData.forEach((currentComponentData) => {
      /** Find component object in frontendMetadata */
      const obj = frontendMetadata.find(
        (o) => o.componentIdentifier === currentComponentData.name,
      );
      /** Update component color */
      // let color2 = Math.floor(Math.random() * 4);
      // obj.status = color2;
      obj.status = currentComponentData.color
        ? currentComponentData.color
        : obj.status;
      const componentDataArray = obj.componentData.find(
        (o) =>
          o.description === currentComponentData.componentData[0].description,
      );
      if (componentDataArray) {
        componentDataArray.value = currentComponentData.componentData[0].value;
      } else {
        obj.componentData.push({
          description: currentComponentData.componentData[0].description,
          value: currentComponentData.componentData[0].value,
        });
      }
    });

  // UPDATE RCB color
  updateRcbRealTimeColoring(frontendMetadata);

  return mixedMetadataWithDataFromBothFrontendAndSignalR;
};

export const getModifiedMetadataWithRealDataFromBackend = (
  frontendMetadata,
  componentsDataForCurrentDomain,
) => {
  const modifiedBackendData = {};
  const keysTraversed = [];
  let mixedMetadataWithDataFromBothFrontendAndBackend = [];
  if (!isEmpty(componentsDataForCurrentDomain)) {
    componentsDataForCurrentDomain.forEach((currentComponentData) => {
      if (keysTraversed.indexOf(currentComponentData.name) === -1) {
        keysTraversed.push(currentComponentData.name);
        modifiedBackendData[currentComponentData.name] = currentComponentData;
      }
    });
    mixedMetadataWithDataFromBothFrontendAndBackend = frontendMetadata.map(
      (currentComponentData) => {
        const dataForComponentFromBackend =
          modifiedBackendData[currentComponentData.componentIdentifier];
        if (dataForComponentFromBackend) {
          currentComponentData.data = dataForComponentFromBackend.data || {};
          currentComponentData.componentData =
            dataForComponentFromBackend.componentData;
          currentComponentData.status = dataForComponentFromBackend.color;
        }
        return currentComponentData;
      },
    );
  }
  return mixedMetadataWithDataFromBothFrontendAndBackend;
};
